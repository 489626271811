import { ChevronDownIcon } from '@heroicons/react/16/solid';
import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  useReactTable,
  TableOptions,
  PaginationState,
  RowData,
  getPaginationRowModel,
  Updater,
  getExpandedRowModel,
  Row,
} from '@tanstack/react-table';
import { Table } from '@tanstack/table-core';
import React, {
  useMemo,
  Fragment,
  useState,
  isValidElement,
  cloneElement,
  FC,
  ReactElement,
  createElement,
  Dispatch,
  SetStateAction,
} from 'react';
import { useTranslation } from 'react-i18next';

import { Option } from 'common/types';

import { Select } from '../inputs';

export type ExpandProps<TData extends RowData> = {
  row: Row<TData>;
};

export type FiltersProps<TData extends RowData> = {
  table: Table<TData>;
  setPagination: Dispatch<SetStateAction<PaginationState>>;
};

export type TableProps<TData extends RowData> = {
  data: TData[];
  totalCount: number;
  expand?: ReactElement<ExpandProps<TData>> | FC<ExpandProps<TData>>;
  filters?: ReactElement<FiltersProps<TData>> | FC<FiltersProps<TData>>;
  columns: ColumnDef<TData>[];
  options?: Partial<TableOptions<TData>>;
  onPaginationChanged?: (args: PaginationState) => Promise<void>;
  paginate?: boolean;
  ordinal?: boolean;
};

const AppTable = <TData extends RowData>({
  data,
  expand,
  filters,
  columns,
  totalCount,
  options = {},
  ordinal = true,
  paginate = true,
  onPaginationChanged,
}: TableProps<TData>): JSX.Element => {
  const { t } = useTranslation();
  const [pagination, setPagination] = useState<PaginationState>(() => ({
    pageIndex: options?.initialState?.pagination?.pageIndex ?? 0,
    pageSize: options?.initialState?.pagination?.pageSize ?? 10,
  }));
  const [expanded, setExpanded] = useState<Record<string, boolean>>(
    () => (options.initialState?.expanded as Record<string, boolean>) ?? {}
  );

  const toggleRowExpansion = (rowId: string) => {
    setExpanded((prev) => ({
      ...prev,
      [rowId]: !prev[rowId],
    }));
  };

  const onHandlePaginationChanged = (
    paginationUpdater: Updater<PaginationState>
  ) => {
    const newPagination =
      typeof paginationUpdater === 'function'
        ? paginationUpdater(pagination)
        : paginationUpdater;

    setPagination(newPagination);
    setExpanded({});

    if (onPaginationChanged) {
      onPaginationChanged(newPagination);
    }
  };

  const defaultData = useMemo(() => [], []);
  const pageSizeOptions = useMemo<Option[]>(
    () =>
      ['5', '10', '30', '50'].map((size) => ({
        label: size,
        value: size,
      })),
    []
  );
  const allColumns = useMemo<ColumnDef<TData>[]>(() => {
    const expandColumn: ColumnDef<TData> = {
      id: 'expander',
      header: '',
      cell: ({ row }) => {
        return (
          <ChevronDownIcon
            className={`w-5 ${expanded[row.id] ? 'rotate-180' : ''}`}
            onClick={() => toggleRowExpansion(row.id)}
          />
        );
      },
    };

    const ordinalСolumn: ColumnDef<TData> = {
      id: 'ordinal',
      header: t('components.table.number'),
      cell: ({ table, row }) => {
        return data.length === totalCount
          ? row.index + 1
          : table.getState().pagination.pageIndex *
              table.getState().pagination.pageSize +
              (row.index + 1);
      },
    };

    return [
      ...(expand ? [expandColumn] : []),
      ...(ordinal ? [ordinalСolumn] : []),
      ...columns,
    ];
  }, [columns, expanded, data, totalCount]);

  const mergedOptions: TableOptions<TData> = {
    columns: allColumns,
    rowCount: totalCount,
    data: data ?? defaultData,
    onPaginationChange: onHandlePaginationChanged,
    getCoreRowModel: getCoreRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    autoResetPageIndex: false,
    manualPagination: true,
    debugTable: false,
    state: {
      pagination,
      expanded,
    },
    ...options,
  };

  const table = useReactTable(mergedOptions);
  const {
    getHeaderGroups,
    getRowModel,
    getRowCount,
    firstPage,
    getCanPreviousPage,
    getCanNextPage,
    previousPage,
    lastPage,
    nextPage,
    getState,
    getPageCount,
    setPageSize,
  } = table;

  const defaultPageSize =
    pageSizeOptions.find(({ value }) => {
      try {
        return parseInt(value) === getState().pagination.pageSize;
      } catch (error) {
        return false;
      }
    }) ?? pageSizeOptions[0];

  return (
    <div>
      {filters && (
        <Fragment>
          {isValidElement(filters)
            ? cloneElement(filters, { table, setPagination })
            : typeof filters === 'function'
              ? createElement(filters, { table, setPagination })
              : null}
          <div className="h-4" />
        </Fragment>
      )}
      <div className="overflow-x-auto">
        <table className="min-w-full divide-y divide-gray-200 border border-gray-300">
          <thead className="bg-gray-50">
            {getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <th
                    key={header.id}
                    className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                  >
                    {flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody className="divide-y divide-gray-200 bg-white">
            {totalCount > 0 ? (
              getRowModel().rows.map((row) => (
                <Fragment key={row.id}>
                  <tr className="hover:bg-gray-100">
                    {row.getVisibleCells().map((cell) => (
                      <td
                        key={cell.id}
                        className="whitespace-nowrap px-6 py-4 text-sm text-gray-900"
                      >
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </td>
                    ))}
                  </tr>
                  {expanded[row.id] && expand && (
                    <tr>
                      <td colSpan={columns.length + 1}>
                        {isValidElement(expand)
                          ? cloneElement(expand, { row })
                          : typeof expand === 'function'
                            ? createElement(expand, { row })
                            : null}
                      </td>
                    </tr>
                  )}
                </Fragment>
              ))
            ) : (
              <tr>
                <td colSpan={columns.length + 1} className="px-6 py-2">
                  <h5>{t('noData.general')}</h5>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      {paginate && (
        <Fragment>
          <div className="h-4" />
          <div className="flex flex-col items-center justify-between gap-4 md:flex-row">
            <div className="flex flex-row items-center gap-2">
              <button
                className="button-s"
                onClick={firstPage}
                disabled={!getCanPreviousPage()}
              >
                {'<<'}
              </button>
              <button
                className="button-s"
                onClick={previousPage}
                disabled={!getCanPreviousPage()}
              >
                {'<'}
              </button>
              <button
                className="button-s"
                onClick={nextPage}
                disabled={!getCanNextPage()}
              >
                {'>'}
              </button>
              <button
                className="button-s"
                onClick={lastPage}
                disabled={!getCanNextPage()}
              >
                {'>>'}
              </button>
            </div>
            <div className="flex flex-row items-center gap-4">
              <p className="text-black">
                {t('components.table.total', {
                  total: getRowCount(),
                })}
              </p>
              <p className="text-black">
                {t('components.table.page', {
                  current: getState().pagination.pageIndex + 1,
                  of: getPageCount(),
                })}
              </p>
              <div className="min-w-24">
                <Select
                  options={pageSizeOptions}
                  defaultValue={defaultPageSize}
                  onChange={(newValue) =>
                    setPageSize(Number((newValue as Option).value))
                  }
                />
              </div>
            </div>
          </div>
        </Fragment>
      )}
    </div>
  );
};

export default AppTable;
