import { useFetch } from 'common/hooks';
import { actionsRepository } from 'common/repositories';

export type ActionsFetchArgs = Parameters<
  typeof actionsRepository.getActions
>[0];

export const useFetchActionsWithTimer = (args: ActionsFetchArgs) => {
  const { data, ...hook } = useFetch(actionsRepository.getActions, {
    instantFetch: true,
    args,
  });

  return { data: data?.data, ...hook };
};
