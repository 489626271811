import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { useFetchSessions } from 'common/stores';
import { Wrapper } from 'core/components/common';
import { CircularIndicator } from 'core/components/uikit';
import { PATHS } from 'core/router';

import { SessionItem } from './components';

const SessionList = () => {
  const { t } = useTranslation();
  const { data, isLoading } = useFetchSessions();

  return (
    <Wrapper internalClassName="flex flex-col items-start">
      <h2 className="mb-6">{t('pages.sessions.title')}</h2>
      <p className="mb-6">{t('pages.sessions.subtitle')}</p>
      {isLoading ? (
        <CircularIndicator />
      ) : data?.length === 0 ? (
        <Link to={PATHS.createSessionKey} className="button">
          {t('pages.sessions.createSession')}
        </Link>
      ) : (
        <div className="flex w-full flex-col gap-4">
          {data?.map((item) => (
            <SessionItem key={item.sessionId} data={item} />
          ))}
        </div>
      )}
    </Wrapper>
  );
};

export default SessionList;
