import {
  Switch as ElSwitch,
  SwitchProps as ElSwitchProps,
} from '@headlessui/react';
import { clsx } from 'clsx';
import React, { FC } from 'react';

export type SwitchProps = ElSwitchProps;

const Switch: FC<SwitchProps> = ({ className, disabled, ...otherProps }) => {
  return (
    <ElSwitch
      {...otherProps}
      disabled={disabled}
      className={clsx(
        'group relative flex h-7 w-14 min-w-[56px] cursor-pointer rounded-full p-1 transition-colors duration-200 ease-in-out focus:outline-none',
        disabled
          ? 'cursor-not-allowed bg-red-600/40 data-[checked]:bg-green-600/40'
          : 'bg-red-600 data-[checked]:bg-green-600',
        className
      )}
    >
      <span
        aria-hidden="true"
        className="pointer-events-none inline-block size-5 -translate-x-[14px] rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out group-data-[checked]:translate-x-[14px]"
      />
    </ElSwitch>
  );
};

export default Switch;
