import { t } from 'i18next';
import React from 'react';
import { FieldValues, useController } from 'react-hook-form';
import * as yup from 'yup';

import { Option } from 'common/types';

import { DefaultFormInputProps } from './types';
import { Select, SelectProps } from '../uikit';

export const createMultiSelectInputSchema = ({
  name,
  required = false,
}: {
  name: string;
  required: boolean;
}) => {
  return yup
    .array()
    .of(yup.string())
    .transform((value) => (value && value.length === 0 ? null : value))
    .when([], {
      is: () => required,
      then: (schema) =>
        schema
          .required(t('errors.validation.isRequired', { name }))
          .min(1, t('errors.validation.isRequired', { name })),
      otherwise: (schema) =>
        schema
          .nullable()
          .notRequired()
          .min(1, t('errors.validation.isRequired', { name })),
    });
};

const SelectInput = <FormValues extends FieldValues>({
  componentProps,
  ...input
}: DefaultFormInputProps<FormValues, SelectProps>) => {
  const { field, fieldState } = useController(input);

  const error = fieldState.error;
  const errorMessage = Array.isArray(error)
    ? error.length > 0
      ? error[0].message
      : undefined
    : error?.message;
  const { onChange, value } = field;
  const { isMulti, options = [] } = componentProps ?? {};

  const handleChange = (newValue: unknown) => {
    if (isMulti) {
      onChange((newValue as Option[]).map((option) => option.value));
    } else {
      onChange((newValue as Option).value);
    }
  };

  const currentOptions = isMulti
    ? options?.filter((option) => value?.includes((option as Option).value))
    : options?.find((option) => (option as Option).value === value);

  return (
    <Select
      {...componentProps}
      value={currentOptions}
      error={errorMessage}
      onChange={handleChange}
    />
  );
};

export default SelectInput;
