import { t } from 'i18next';
import * as yup from 'yup';

import { phoneSchema } from 'core/components/form';

export const loginSchema = yup
  .object({
    phone: phoneSchema,
    password: yup
      .string()
      .required(
        t('errors.validation.isRequired', {
          name: t('pages.phone.fields.password'),
        })
      )
      .min(
        8,
        t('errors.validation.minLength', {
          name: t('pages.phone.fields.password'),
          length: 8,
        })
      )
      .matches(
        /[A-Z]/,
        t('errors.validation.password.uppercase', {
          name: t('pages.phone.fields.password'),
        })
      )
      .matches(
        /[a-z]/,
        t('errors.validation.password.lowercase', {
          name: t('pages.phone.fields.password'),
        })
      )
      .matches(
        /\d/,
        t('errors.validation.password.number', {
          name: t('pages.phone.fields.password'),
        })
      )
      .matches(
        /[@$!%*?&_-]/,
        t('errors.validation.password.specialCharacter', {
          name: t('pages.phone.fields.password'),
        })
      ),
  })
  .required();

export type LoginFormData = yup.InferType<typeof loginSchema>;
