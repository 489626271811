import { t } from 'i18next';
import React from 'react';
import {
  FieldValues,
  useController,
  UseControllerProps,
} from 'react-hook-form';
import * as yup from 'yup';

import { Range, RangeProps } from '../uikit';

export function createRangeInputSchema({
  name,
  required,
}: {
  name: string;
  required: boolean;
}) {
  return yup
    .array()
    .of(yup.string().nullable())
    .transform((value) => (value && value.length === 0 ? null : value))
    .when([], {
      is: () => required,
      then: (schema) =>
        schema
          .required(t('errors.validation.isRequired', { name }))
          .test(
            'empty-or-valid',
            t('errors.validation.range.mustIncludeBoth', { name }),
            (values) => !values || values.length === 0 || values.length === 2
          ),
      otherwise: (schema) =>
        schema
          .nullable()
          .notRequired()
          .test(
            'empty-or-valid',
            t('errors.validation.range.mustIncludeBoth', { name }),
            (values) => !values || values.length === 0 || values.length === 2
          ),
    });
}
export type RangeValue<T = string> = [T, T];

export type RangeInputProps<
  FormValues extends FieldValues,
  ValueT = number,
> = UseControllerProps<FormValues> & {
  componentProps: Omit<RangeProps<ValueT>, 'value' | 'error' | 'onChange'>;
};

const RangeInput = <FormValues extends FieldValues, ValueT = number>({
  componentProps,
  ...input
}: RangeInputProps<FormValues, ValueT>) => {
  const { field, fieldState } = useController(input);

  const error = fieldState.error?.message;
  const { value, onChange } = field;

  return (
    <Range
      {...componentProps}
      error={error}
      value={value}
      onChange={onChange}
    />
  );
};

export default RangeInput;
