import { useFetch } from 'common/hooks';
import { sessionsRepository } from 'common/repositories';

export type SessionTickersFetchArgs = Parameters<
  typeof sessionsRepository.getSessionTickersById
>[0];

export const useFetchSessionTickers = (args: SessionTickersFetchArgs) => {
  const { data, ...hook } = useFetch(sessionsRepository.getSessionTickersById, {
    instantFetch: true,
    args,
  });

  return { data: data?.data, ...hook };
};
