import { Field, Label } from '@headlessui/react';
import { clsx } from 'clsx';
import React, { useState } from 'react';

import Checkbox from './Checkbox';
import { InputProps } from './types';
import { Option } from '../../../../common/types';

export type CheckboxGroupProps<T> = InputProps & {
  options: Option<T>[];
  values?: T[];
  disabled?: boolean;
  onChange?: (values: T[]) => void;
};

const CheckboxGroup = <T extends string>({
  error,
  label,
  options,
  values,
  disabled,
  onChange,
  className,
}: CheckboxGroupProps<T>) => {
  const [checkedValues, setCheckedValues] = useState(values ?? []);

  const handleCheckboxChange = (value: T) => {
    const newValue = checkedValues.includes(value)
      ? checkedValues.filter((v: string) => v !== value)
      : [...checkedValues, value];

    setCheckedValues(newValue);

    if (onChange) onChange(newValue);
  };

  return (
    <Field className={clsx('flex flex-col gap-1', className)}>
      {label && <Label className="caption text-xs">{label}</Label>}
      {options.map(({ label, value }) => (
        <Checkbox
          key={value}
          value={value}
          label={label}
          checked={checkedValues.includes(value)}
          onChange={() => handleCheckboxChange(value)}
          disabled={disabled}
        />
      ))}
      {error && (
        <p className="caption ml-1 mt-1 text-sm text-appRose">{error}</p>
      )}
    </Field>
  );
};

export default CheckboxGroup;
