import { yupResolver } from '@hookform/resolvers/yup';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

// import { useAuthStore } from 'common/stores';
import { Wrapper } from 'core/components/common';
import { PhoneInput, TextInput } from 'core/components/form';
import CircularIndicator from 'core/components/uikit/CircularIndicator';
import { PATHS } from 'core/router';

import { LoginFormData, loginSchema } from './validationSchema';

const Phone = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const {
    control,
    handleSubmit,
    formState: { isValid, isSubmitting },
  } = useForm<LoginFormData>({
    resolver: yupResolver(loginSchema),
    mode: 'all',
    defaultValues: { phone: '', password: '' },
  });

  const isButtonDisabled = !isValid || isSubmitting;

  const onSubmit = handleSubmit(async ({ phone, password }) => {
    console.log({ phone, password });
    // const success = await useAuthStore
    //   .getState()
    //   .sendPhoneCode({ phone, password });

    // if (!success) return;

    navigate(PATHS.getPhoneVerify({ phone }));
  });

  return (
    <Wrapper internalClassName="!max-w-md">
      <form className="flex flex-col gap-4" onSubmit={onSubmit}>
        <PhoneInput control={control} name="phone" />
        <TextInput
          name="password"
          control={control}
          componentProps={{
            type: 'password',
            placeholder: 'Password',
          }}
        />
        <button type="submit" disabled={isButtonDisabled}>
          {isSubmitting ? <CircularIndicator /> : t('common.continue')}
        </button>
      </form>
    </Wrapper>
  );
};

export default Phone;
