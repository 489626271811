import React from 'react';
import { useTranslation } from 'react-i18next';

import { Wrapper } from 'core/components/common';

const Reports = () => {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <h2 className="mb-6">{t('pages.reports.title')}</h2>
    </Wrapper>
  );
};

export default Reports;
