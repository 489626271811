import React, { FC } from 'react';
import { UseControllerProps } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { TextInput } from 'core/components/form';

import { CreateEditSessionFormData } from '../validationSchema';

export type ClosingParametersProps = Pick<
  UseControllerProps<CreateEditSessionFormData>,
  'control'
> & {
  disabled?: boolean;
};

const ClosingParameters: FC<ClosingParametersProps> = ({
  control,
  disabled,
}) => {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col gap-2">
      <h4>{t('pages.createSession.closePositionParams.title')}</h4>
      {[1, 2].map((number, index) => (
        <div key={number} className="flex flex-row justify-between gap-2">
          <TextInput
            control={control}
            name={`closePositionParams.stopLossSteps.${index}.positionSize`}
            componentProps={{
              disabled,
              type: 'number',
              label: t(
                'pages.createSession.closePositionParams.form.stopLossSteps.positionSize.label',
                { number }
              ),
            }}
          />
          <TextInput
            control={control}
            name={`closePositionParams.stopLossSteps.${index}.priceChangePercent`}
            componentProps={{
              disabled,
              type: 'number',
              label: t(
                'pages.createSession.closePositionParams.form.stopLossSteps.priceChangePercent.label'
              ),
            }}
          />
        </div>
      ))}

      <div className="flex flex-row justify-between gap-2">
        <TextInput
          control={control}
          name="closePositionParams.takeProfitSteps.0.priceChangePercent"
          componentProps={{
            disabled,
            type: 'number',
            label: t(
              'pages.createSession.closePositionParams.form.takeProfitSteps.priceChangePercent.label',
              { number: 1 }
            ),
          }}
        />
      </div>
    </div>
  );
};

export default ClosingParameters;
