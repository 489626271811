import {
  DEPRECATED_CLOSE_POSITION_ORDER_TYPE,
  DEPRECATED_EXCHANGE,
  Action,
  DEPRECATED_MARKET_STATUS,
  DEPRECATED_OPEN_POSITION_ORDER_TYPE,
  Order,
  Position,
  DeprecatedSession,
  DEPRECATED_SESSION_STATUS,
  DEPRECATED_SESSION_SEARCH_MONITOR_STATUS,
  SessionTickers,
  TICKER_TYPE,
  Session,
} from 'common/models';

export const GET_DEPRECATED_SESSION = ({
  id,
  name,
  status,
  marketStatus,
  monitorStatus,
}: {
  id: string;
  name: string;
  status: DeprecatedSession['status'];
  marketStatus: DeprecatedSession['marketStatus'];
  monitorStatus: DeprecatedSession['monitorStatus'];
}): DeprecatedSession => ({
  sessionId: id,
  sessionName: name,
  rthParams: {
    active: true,
    marketHoursRange: ['9:30 AM', '4:00 PM'],
    filterParams: {
      exchanges: [DEPRECATED_EXCHANGE.NASDAQ],
    },
    triggerParams: {
      lastPeriodInMinutes: 10,
      lastPeriodPriceChangePercent: 10,
      priceChangePercentFromPrevClose: 10,
    },
    openPositionParams: {
      limitOrderRefreshIntervalInSec: 0,
      positionSizeIn$: 1,
      entryIntervalInSec: 0,
      entriesCount: 1,
      orderType: DEPRECATED_OPEN_POSITION_ORDER_TYPE.ASK,
      chaseDown: 1,
      additionalPositions: [
        {
          positionSize: 100,
          increaseFromInitialBuy: 10,
        },
      ],
    },
  },
  ahParams: {
    active: true,
    marketHoursRange: ['4:00 PM', '8:00 PM'],
    filterParams: {
      exchanges: [DEPRECATED_EXCHANGE.NASDAQ],
    },
    triggerParams: {
      lastPeriodInMinutes: 10,
      lastPeriodPriceChangePercent: 10,
      priceChangePercentFromPrevClose: 10,
    },
    openPositionParams: {
      limitOrderRefreshIntervalInSec: 0,
      positionSizeIn$: 1,
      entryIntervalInSec: 0,
      entriesCount: 1,
      orderType: DEPRECATED_OPEN_POSITION_ORDER_TYPE.ASK,
      chaseDown: 1,
      additionalPositions: [
        {
          positionSize: 100,
          increaseFromInitialBuy: 10,
        },
      ],
    },
  },
  phParams: {
    active: false,
    marketHoursRange: ['4:00 AM', '9:30 AM'],
    filterParams: {
      exchanges: [DEPRECATED_EXCHANGE.NASDAQ],
    },
    triggerParams: {
      lastPeriodInMinutes: 10,
      lastPeriodPriceChangePercent: 10,
      priceChangePercentFromPrevClose: 10,
    },
    openPositionParams: {
      limitOrderRefreshIntervalInSec: 0,
      positionSizeIn$: 1,
      entryIntervalInSec: 30,
      entriesCount: 1,
      orderType: DEPRECATED_OPEN_POSITION_ORDER_TYPE.ASK,
      chaseDown: 1,
      additionalPositions: [
        {
          positionSize: 100,
          increaseFromInitialBuy: 10,
        },
      ],
    },
  },
  closePositionParams: {
    stopLossSteps: [
      {
        positionSize: 10,
        priceChangePercent: 10,
      },
      {
        positionSize: 10,
        priceChangePercent: 10,
      },
    ],
    takeProfitSteps: [
      {
        priceChangePercent: 10,
      },
    ],
    timeBasedParams: {
      closeAllPositionsDate: '1:00 PM',
      orderType: DEPRECATED_CLOSE_POSITION_ORDER_TYPE.ASK,
      limitOrderRefreshIntervalInSec: 10,
      closeSteps: [
        {
          date: '01:00 PM',
          positionSizePercent: 10,
        },
        {
          date: '02:00 PM',
          positionSizePercent: 20,
        },
      ],
    },
  },
  blacklist: ['AAPL'],
  status,
  marketStatus,
  searchStatus: null,
  lastActions: null,
  watchlist: null,
  monitorStatus,
  positions: GET_SESSION_POSITIONS({ id }),
});

export const GET_SESSION_POSITIONS = ({ id }: { id: string }): Position[] => [
  {
    id: '1',
    ticker: 'AAPL',
    type: 'short',
    quantity: 3,
    avgPrice: 300.0,
    currentSize: 300.0,
    stopLossPrice: null,
    takeProfitPrice: null,
    status: 'open',
    openDate: new Date('2024-11-12T00:20:15'),
    closeDate: null,
    currentPrice: 310.0,
    marketType: 'ahMarket',
    sessionId: id,
  },
  {
    id: '2',
    ticker: 'AAPL',
    type: 'short',
    quantity: 3,
    avgPrice: 300.0,
    currentSize: 300.0,
    stopLossPrice: null,
    takeProfitPrice: null,
    status: 'closed',
    openDate: new Date('2024-11-12T00:20:15'),
    closeDate: null,
    currentPrice: 310.0,
    marketType: 'ahMarket',
    sessionId: id,
  },
  {
    id: '3',
    ticker: 'AAPL',
    type: 'short',
    quantity: 3,
    avgPrice: 300.0,
    currentSize: 300.0,
    stopLossPrice: null,
    takeProfitPrice: null,
    status: 'pending',
    openDate: new Date('2024-11-12T00:20:15'),
    closeDate: null,
    currentPrice: 310.0,
    marketType: 'ahMarket',
    sessionId: id,
  },
];

export const GET_POSITION_ORDERS = ({ id }: { id: string }): Order[] => [
  {
    id: '1',
    ticker: 'string',
    externalId: '1',
    status: 'executing',
    side: 'buy',
    type: 'market',
    priceType: 'market',
    entryPrice: 0,
    executedPrice: null,
    quantity: 0,
    sentDate: new Date('2024-11-12T00:20:15'),
    filledDate: new Date('2024-11-12T00:20:15'),
    updatedDate: new Date('2024-11-12T00:20:15'),
    positionId: id,
    closeTriggerType: null,
    sizeOfClosingPositionPart: null,
  },
  {
    id: '2',
    ticker: 'string',
    externalId: '2',
    status: 'executing',
    side: 'buy',
    type: 'market',
    priceType: 'market',
    entryPrice: 0,
    executedPrice: null,
    quantity: 0,
    sentDate: new Date('2024-11-12T00:20:15'),
    filledDate: new Date('2024-11-12T00:20:15'),
    updatedDate: new Date('2024-11-12T00:20:15'),
    positionId: id,
    closeTriggerType: null,
    sizeOfClosingPositionPart: null,
  },
];

export const GET_SESSION_TICKER = (): SessionTickers => ({
  ahMarket: [
    {
      price: {
        lastPrice: 10,
        bidPrice: 10,
        askPrice: 10,
      },
      ticker: 'AAPL',
      updatedAt: new Date(),
      marketCap: 2500000000000,
      listType: TICKER_TYPE.WATCHLIST,
      fromWhitelist: true,
      prevCloseTrigger: 120,
      lastPeriodTrigger: 125,
      currentDayVolumeIn$: 5000000,
      lastPeriodVolumeIn$: 4800000,
      lastPeriodInMinutes: 60,
      priceChangePercentFromPrevClose: 2.5,
    },
    {
      price: {
        lastPrice: 10,
        bidPrice: 10,
        askPrice: 10,
      },
      ticker: 'GOOGL',
      updatedAt: new Date(),
      marketCap: 1500000000000,
      listType: TICKER_TYPE.PRE_WATCHLIST,
      fromWhitelist: false,
      prevCloseTrigger: 980,
      lastPeriodTrigger: 990,
      currentDayVolumeIn$: 4000000,
      lastPeriodVolumeIn$: 3900000,
      lastPeriodInMinutes: 60,
      priceChangePercentFromPrevClose: 0.8,
    },
  ],
  phMarket: [
    {
      price: {
        lastPrice: 10,
        bidPrice: 10,
        askPrice: 10,
      },
      ticker: 'MSFT',
      updatedAt: new Date(),
      marketCap: 2200000000000,
      listType: TICKER_TYPE.WATCHLIST,
      fromWhitelist: true,
      prevCloseTrigger: 55,
      lastPeriodTrigger: 57,
      currentDayVolumeIn$: 3000000,
      lastPeriodVolumeIn$: 2900000,
      lastPeriodInMinutes: 45,
      priceChangePercentFromPrevClose: 3.2,
    },
    {
      price: {
        lastPrice: 10,
        bidPrice: 10,
        askPrice: 10,
      },
      ticker: 'NFLX',
      updatedAt: new Date(),
      marketCap: 150000000000,
      listType: TICKER_TYPE.PRE_WATCHLIST,
      fromWhitelist: false,
      prevCloseTrigger: 43,
      lastPeriodTrigger: 45,
      currentDayVolumeIn$: 2500000,
      lastPeriodVolumeIn$: 2400000,
      lastPeriodInMinutes: 30,
      priceChangePercentFromPrevClose: 1.5,
    },
  ],
  rthMarket: [
    {
      price: {
        lastPrice: 10,
        bidPrice: 10,
        askPrice: 10,
      },
      ticker: 'AMZN',
      updatedAt: new Date(),
      marketCap: 1800000000000,
      listType: TICKER_TYPE.WATCHLIST,
      fromWhitelist: true,
      prevCloseTrigger: 208,
      lastPeriodTrigger: 211,
      currentDayVolumeIn$: 7000000,
      lastPeriodVolumeIn$: 6800000,
      lastPeriodInMinutes: 50,
      priceChangePercentFromPrevClose: 1.0,
    },
    {
      price: {
        lastPrice: 10,
        bidPrice: 10,
        askPrice: 10,
      },
      ticker: 'TSLA',
      updatedAt: new Date(),
      marketCap: 800000000000,
      listType: TICKER_TYPE.PRE_WATCHLIST,
      fromWhitelist: false,
      prevCloseTrigger: 348,
      lastPeriodTrigger: 352,
      currentDayVolumeIn$: 6000000,
      lastPeriodVolumeIn$: 5800000,
      lastPeriodInMinutes: 55,
      priceChangePercentFromPrevClose: 0.8,
    },
  ],
});

export const ACTIONS: Action[] = [
  { date: new Date('2024-11-12T00:15:00'), type: 'STATUS', text: 'Start Bot' },
  { date: new Date('2024-11-12T00:16:00'), type: 'ALERT', text: 'GME $20.50' },
  {
    date: new Date('2024-11-12T00:17:30'),
    type: 'TRADE',
    text: 'Checking market conditions',
  },
  {
    date: new Date('2024-11-12T00:18:45'),
    type: 'ALERT',
    text: 'TSLA $650.00',
  },
  {
    date: new Date('2024-11-12T00:20:15'),
    type: 'STATUS',
    text: 'Executing Buy Order',
  },
  {
    date: new Date('2024-11-12T00:21:00'),
    type: 'ALERT',
    text: 'AAPL $120.00',
  },
  {
    date: new Date('2024-11-12T00:22:10'),
    type: 'TRADE',
    text: 'Order executed',
  },
  {
    date: new Date('2024-11-12T00:23:50'),
    type: 'STATUS',
    text: 'Monitoring trades',
  },
  {
    date: new Date('2024-11-12T00:25:20'),
    type: 'ALERT',
    text: 'MSFT $200.30',
  },
  {
    date: new Date('2024-11-12T00:27:00'),
    type: 'TRADE',
    text: 'Market volatility detected',
  },
  {
    date: new Date('2024-11-12T00:28:15'),
    type: 'STATUS',
    text: 'Adjusting strategy',
  },
  { date: new Date('2024-11-12T00:29:30'), type: 'ALERT', text: 'FB $250.75' },
  {
    date: new Date('2024-11-12T00:31:05'),
    type: 'STATUS',
    text: 'Re-evaluating conditions',
  },
  {
    date: new Date('2024-11-12T00:33:00'),
    type: 'ALERT',
    text: 'AMZN $3050.25',
  },
  {
    date: new Date('2024-11-12T00:34:45'),
    type: 'TRADE',
    text: 'Holding positions',
  },
  {
    date: new Date('2024-11-12T00:36:00'),
    type: 'STATUS',
    text: 'Updating logs',
  },
];

export const DEPRECATED_SESSIONS = [
  GET_DEPRECATED_SESSION({
    id: '1',
    name: 'Session 1',
    status: DEPRECATED_SESSION_STATUS.ACTIVE,
    marketStatus: DEPRECATED_MARKET_STATUS.RTH_MARKET,
    monitorStatus: DEPRECATED_SESSION_SEARCH_MONITOR_STATUS.RTH_MARKET,
  }),
  GET_DEPRECATED_SESSION({
    id: '2',
    name: 'Session 2',
    status: DEPRECATED_SESSION_STATUS.STOPPED,
    marketStatus: DEPRECATED_MARKET_STATUS.CLOSED,
    monitorStatus: DEPRECATED_SESSION_SEARCH_MONITOR_STATUS.PAUSED,
  }),
];

export const SESSION_TICKERS: Record<string, SessionTickers> = {
  '1': GET_SESSION_TICKER(),
  '2': GET_SESSION_TICKER(),
};

export const POSITIONS: Position[] = [
  ...GET_SESSION_POSITIONS({
    id: '1',
  }),
  ...GET_SESSION_POSITIONS({
    id: '2',
  }),
];

export const ORDERS: Order[] = [
  ...GET_POSITION_ORDERS({
    id: '1',
  }),
  ...GET_POSITION_ORDERS({
    id: '2',
  }),
];

export const sessionsToDeprecatedSessions = (sessions: Session[]) => {
  const deprecatedSessions: DeprecatedSession[] = sessions.map((item) => ({
    sessionId: item.sessionId,
    sessionName: item.sessionName,
    rthParams: item.rthMarket
      ? {
          active: item.rthMarket.active,
          marketHoursRange: item.rthMarket.marketHoursRange,
          filterParams: item.rthMarket.filterParams
            ? {
                priceChangePercentFromPrevCloseRange: {
                  min:
                    item?.rthMarket?.filterParams
                      .priceChangePercentFromPrevCloseRange?.[0] ?? 0,
                  max:
                    item?.rthMarket?.filterParams
                      .priceChangePercentFromPrevCloseRange?.[1] ?? 0,
                },
                currentDayVolumeIn$Range: {
                  min:
                    item.rthMarket.filterParams.currentDayVolumeIn$Range?.[0] ??
                    0,
                  max:
                    item.rthMarket.filterParams.currentDayVolumeIn$Range?.[1] ??
                    0,
                },
                lastPeriodVolumeIn$Range: {
                  min:
                    item.rthMarket.filterParams.lastPeriodVolumeIn$Range?.[0] ??
                    0,
                  max:
                    item.rthMarket.filterParams.lastPeriodVolumeIn$Range?.[1] ??
                    0,
                },

                lastPeriodInMinutes:
                  item.rthMarket.filterParams.lastPeriodInMinutes,
                marketCapRange: {
                  min: item.rthMarket.filterParams.marketCapRange?.[0] ?? 0,
                  max: item.rthMarket.filterParams.marketCapRange?.[1] ?? 0,
                },
                priceRange: {
                  min: item.rthMarket.filterParams.priceRange?.[0] ?? 0,
                  max: item.rthMarket.filterParams.priceRange?.[1] ?? 0,
                },
                exchanges: item.rthMarket.filterParams.exchanges,
              }
            : null,
          triggerParams: item.rthMarket.triggerParams,
          openPositionParams: item.rthMarket.openPositionParams
            ? {
                limitOrderRefreshIntervalInSec: parseInt(
                  item.rthMarket.openPositionParams.orderRefreshInterval,
                  10
                ),
                orderType: item.rthMarket.openPositionParams.orderType,
                entryIntervalInSec: parseInt(
                  item.rthMarket.openPositionParams.openEntryLimit,
                  10
                ),
                positionSizeIn$:
                  item.rthMarket.openPositionParams.maxEntryVolume,
                entriesCount:
                  item.rthMarket.openPositionParams.maxPositionVolume,
                chaseDown: item.rthMarket.openPositionParams.chaseDown,
                additionalPositions:
                  item.rthMarket.openPositionParams.additionalSteps.map(
                    (step) => ({
                      positionSize: step.entrySize,
                      increaseFromInitialBuy: step.triggeringPriceChange,
                    })
                  ),
              }
            : null,
        }
      : null,
    phParams: item.phMarket
      ? {
          active: item.phMarket.active,
          marketHoursRange: item.phMarket.marketHoursRange,
          filterParams: item.phMarket.filterParams
            ? {
                priceChangePercentFromPrevCloseRange: {
                  min:
                    item?.phMarket?.filterParams
                      .priceChangePercentFromPrevCloseRange?.[0] ?? 0,
                  max:
                    item?.phMarket?.filterParams
                      .priceChangePercentFromPrevCloseRange?.[1] ?? 0,
                },
                currentDayVolumeIn$Range: {
                  min:
                    item.phMarket.filterParams.currentDayVolumeIn$Range?.[0] ??
                    0,
                  max:
                    item.phMarket.filterParams.currentDayVolumeIn$Range?.[1] ??
                    0,
                },
                lastPeriodVolumeIn$Range: {
                  min:
                    item.phMarket.filterParams.lastPeriodVolumeIn$Range?.[0] ??
                    0,
                  max:
                    item.phMarket.filterParams.lastPeriodVolumeIn$Range?.[1] ??
                    0,
                },

                lastPeriodInMinutes:
                  item.phMarket.filterParams.lastPeriodInMinutes,
                marketCapRange: {
                  min: item.phMarket.filterParams.marketCapRange?.[0] ?? 0,
                  max: item.phMarket.filterParams.marketCapRange?.[1] ?? 0,
                },
                priceRange: {
                  min: item.phMarket.filterParams.priceRange?.[0] ?? 0,
                  max: item.phMarket.filterParams.priceRange?.[1] ?? 0,
                },
                exchanges: item.phMarket.filterParams.exchanges,
              }
            : null,
          triggerParams: item.phMarket.triggerParams,
          openPositionParams: item.phMarket.openPositionParams
            ? {
                limitOrderRefreshIntervalInSec: parseInt(
                  item.phMarket.openPositionParams.orderRefreshInterval,
                  10
                ),
                orderType: item.phMarket.openPositionParams.orderType,
                entryIntervalInSec: parseInt(
                  item.phMarket.openPositionParams.openEntryLimit,
                  10
                ),
                positionSizeIn$:
                  item.phMarket.openPositionParams.maxEntryVolume,
                entriesCount:
                  item.phMarket.openPositionParams.maxPositionVolume,
                chaseDown: item.phMarket.openPositionParams.chaseDown,
                additionalPositions:
                  item.phMarket.openPositionParams.additionalSteps.map(
                    (step) => ({
                      positionSize: step.entrySize,
                      increaseFromInitialBuy: step.triggeringPriceChange,
                    })
                  ),
              }
            : null,
        }
      : null,
    ahParams: item.ahMarket
      ? {
          active: item.ahMarket.active,
          marketHoursRange: item.ahMarket.marketHoursRange,
          filterParams: item.ahMarket.filterParams
            ? {
                priceChangePercentFromPrevCloseRange: {
                  min:
                    item?.ahMarket?.filterParams
                      .priceChangePercentFromPrevCloseRange?.[0] ?? 0,
                  max:
                    item?.ahMarket?.filterParams
                      .priceChangePercentFromPrevCloseRange?.[1] ?? 0,
                },
                currentDayVolumeIn$Range: {
                  min:
                    item.ahMarket.filterParams.currentDayVolumeIn$Range?.[0] ??
                    0,
                  max:
                    item.ahMarket.filterParams.currentDayVolumeIn$Range?.[1] ??
                    0,
                },
                lastPeriodVolumeIn$Range: {
                  min:
                    item.ahMarket.filterParams.lastPeriodVolumeIn$Range?.[0] ??
                    0,
                  max:
                    item.ahMarket.filterParams.lastPeriodVolumeIn$Range?.[1] ??
                    0,
                },

                lastPeriodInMinutes:
                  item.ahMarket.filterParams.lastPeriodInMinutes,
                marketCapRange: {
                  min: item.ahMarket.filterParams.marketCapRange?.[0] ?? 0,
                  max: item.ahMarket.filterParams.marketCapRange?.[1] ?? 0,
                },
                priceRange: {
                  min: item.ahMarket.filterParams.priceRange?.[0] ?? 0,
                  max: item.ahMarket.filterParams.priceRange?.[1] ?? 0,
                },
                exchanges: item.ahMarket.filterParams.exchanges,
              }
            : null,
          triggerParams: item.ahMarket.triggerParams,
          openPositionParams: item.ahMarket.openPositionParams
            ? {
                limitOrderRefreshIntervalInSec: parseInt(
                  item.ahMarket.openPositionParams.orderRefreshInterval,
                  10
                ),
                orderType: item.ahMarket.openPositionParams.orderType,
                entryIntervalInSec: parseInt(
                  item.ahMarket.openPositionParams.openEntryLimit,
                  10
                ),
                positionSizeIn$:
                  item.ahMarket.openPositionParams.maxEntryVolume,
                entriesCount:
                  item.ahMarket.openPositionParams.maxPositionVolume,
                chaseDown: item.ahMarket.openPositionParams.chaseDown,
                additionalPositions:
                  item.ahMarket.openPositionParams.additionalSteps.map(
                    (step) => ({
                      positionSize: step.entrySize,
                      increaseFromInitialBuy: step.triggeringPriceChange,
                    })
                  ),
              }
            : null,
        }
      : null,
    closePositionParams: item?.ahMarket?.closePositionParams
      ? {
          stopLossSteps:
            item?.ahMarket?.closePositionParams?.stopLossParams?.map(
              (param) => ({
                positionSize: param.sizeOfClosingPositionPart,
                priceChangePercent: param.triggeringPriceChange,
              })
            ) ?? null,
          takeProfitSteps:
            item?.ahMarket?.closePositionParams.takeProfitParams?.map(
              (param) => ({
                priceChangePercent: param.triggeringPriceChange,
              })
            ) ?? null,
          timeBasedParams: item?.ahMarket?.closePositionParams.timeBasedParams
            ? {
                closeAllPositionsDate: Date.now().toString(),
                orderType: 'market',
                limitOrderRefreshIntervalInSec: 0,
                closeSteps:
                  item?.ahMarket?.closePositionParams.timeBasedParams.map(
                    (param) => ({
                      date: Date.now().toString(),
                      positionSizePercent: param.sizeOfClosingPositionPart,
                    })
                  ) ?? null,
              }
            : null,
        }
      : null,
    blacklist: item.blacklist,
    status: item.status,
    marketStatus: item.marketStatus,
    searchStatus: item.searchStatus,
    monitorStatus: item.monitorStatus,
    lastActions: [],
    watchlist: null,
    positions: [],
  }));

  return deprecatedSessions;
};
