import {
  Dialog,
  DialogPanel,
  DialogBackdrop,
  DialogTitle,
  Description,
} from '@headlessui/react';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { AsyncCb } from 'common/types';

import PhoneVerifyForm, { PhoneVerifyFormProps } from '../PhoneVerifyForm';

export type ConfirmActionDialogProps = {
  isOpen: boolean;
  title?: string;
  subtitle?: string;
  onConfirm: AsyncCb;
  onClose: () => void;
};

// TODO: the client has asked to postpone this functionality for now
const ConfirmActionDialog: FC<ConfirmActionDialogProps> = ({
  title,
  isOpen,
  onClose,
  subtitle,
  onConfirm,
}) => {
  const { t } = useTranslation();
  const onSubmit: PhoneVerifyFormProps['onSubimit'] = async ({ code }) => {
    console.log({ code });

    // const account = await authStore.verifyPhoneCode({ phone, code });

    // if (!account) return;

    await onConfirm();
  };

  // TODO: get phone from profile store
  return (
    <Dialog open={isOpen} onClose={onClose} className="relative z-50">
      <DialogBackdrop className="fixed inset-0 bg-black/30" />
      <div className="fixed inset-0 flex w-screen items-center justify-center p-4">
        <DialogPanel className="max-w-lg space-y-4 rounded-md bg-white p-12">
          <DialogTitle className="mb-8 text-center">
            {title ?? t('components.confirmActionDialog.title')}
          </DialogTitle>
          {subtitle && (
            <Description className="text-center">{subtitle}</Description>
          )}
          <PhoneVerifyForm phone="" onSubimit={onSubmit} />
        </DialogPanel>
      </div>
    </Dialog>
  );
};

export default ConfirmActionDialog;
