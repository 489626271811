import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { TextInput } from 'core/components/form';
import { DisclosureWithTitle } from 'features/components';

import { CommonInputsProps } from './types';

const TriggerInputs: FC<CommonInputsProps> = ({
  control,
  basename,
  disabled = false,
}) => {
  const { t } = useTranslation();

  return (
    <DisclosureWithTitle
      title={
        <h5>{t('pages.createSession.common.form.triggerParams.title')}</h5>
      }
      defaultOpen={true}
    >
      <div className="flex flex-row items-start gap-3">
        <TextInput
          control={control}
          name={`${basename}.triggerParams.lastPeriodInMinutes`}
          componentProps={{
            disabled,
            type: 'number',
            label: t(
              'pages.createSession.common.form.triggerParams.lastPeriodInMinutes.label'
            ),
          }}
        />
        <TextInput
          control={control}
          name={`${basename}.triggerParams.lastPeriodPriceChangePercent`}
          componentProps={{
            disabled,
            type: 'number',
            label: t(
              'pages.createSession.common.form.triggerParams.lastPeriodPriceChangePercent.label'
            ),
          }}
        />
      </div>
      <p className="caption text-center text-[12px]">
        {t('pages.createSession.and')}
      </p>
      <TextInput
        control={control}
        name={`${basename}.triggerParams.priceChangePercentFromPrevClose`}
        componentProps={{
          disabled,
          type: 'number',
          label: t(
            'pages.createSession.common.form.triggerParams.priceChangePercentFromPrevClose.label'
          ),
        }}
      />
    </DisclosureWithTitle>
  );
};

export default TriggerInputs;
