import React from 'react';
import { RouteObject } from 'react-router-dom';

import {
  SessionList,
  EditSession,
  CreateSession,
  SessionDetails,
} from 'features';

import PATHS from '../paths';
import ProtectedRoute from '../ProtectedRoute';

const sessionRoutes: RouteObject = {
  path: PATHS.sessions,
  children: [
    {
      index: true,
      element: (
        <ProtectedRoute from="notAuthUser">
          <SessionList />
        </ProtectedRoute>
      ),
    },
    {
      path: PATHS.sessionKey,
      children: [
        {
          index: true,
          element: (
            <ProtectedRoute from="notAuthUser">
              <SessionDetails />
            </ProtectedRoute>
          ),
        },
        {
          path: PATHS.sessionEditKey,
          element: (
            <ProtectedRoute from="notAuthUser">
              <EditSession />
            </ProtectedRoute>
          ),
        },
      ],
    },
    {
      path: PATHS.createSessionKey,
      element: (
        <ProtectedRoute from="notAuthUser">
          <CreateSession />
        </ProtectedRoute>
      ),
    },
  ],
};

export default sessionRoutes;
