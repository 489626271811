import moment from 'moment-timezone';

export const minutesFromStartOfDayToFormatedTime = (
  minutes: number
): string => {
  try {
    return moment().startOf('day').add(minutes, 'minutes').format('h:mm A');
  } catch (error) {
    return '';
  }
};

export const formatedTimeToMinutesFromStartOfDay = (time: string): number => {
  try {
    const momentTime = moment(time, 'h:mm A');
    return momentTime.diff(moment().startOf('day'), 'minutes');
  } catch (error) {
    return 0;
  }
};

export type FormatDateWithTimezoneOptions = {
  format?: string;
  timezone?: string;
};

export const formatDateWithTimezone = (
  value: Date,
  {
    format = 'DD MMM YYYY hh:mm:ss A',
    timezone = 'America/New_York',
  }: FormatDateWithTimezoneOptions = {}
) => {
  try {
    return value ? moment(value).tz(timezone).format(format) : '-';
  } catch (error) {
    return '-';
  }
};
