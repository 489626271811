import React, { FC } from 'react';
import { UseControllerProps } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { CLOSE_POSITION_ORDER_TYPE } from 'common/models/session';
import { generateTimeOptions } from 'common/utils';
import { RadioGroupInput, SelectInput, TextInput } from 'core/components/form';

import { CreateEditSessionFormData } from '../validationSchema';

export type TimeBasedPositionCloseProps = Pick<
  UseControllerProps<CreateEditSessionFormData>,
  'control'
> & {
  disabled?: boolean;
};

const TimeBasedPositionClose: FC<TimeBasedPositionCloseProps> = ({
  control,
  disabled,
}) => {
  const { t } = useTranslation();
  const timeOptions = generateTimeOptions();

  return (
    <div className="flex flex-col gap-2">
      <h4>{t('pages.createSession.timeBasedPositionParams.title')}</h4>
      {[1, 2].map((number, index) => (
        <div key={number} className="flex flex-row justify-between gap-2">
          <SelectInput
            control={control}
            name={`closePositionParams.timeBasedParams.closeSteps.${index}.date`}
            componentProps={{
              disabled,
              label: t(
                'pages.createSession.timeBasedPositionParams.form.closeSteps.date.label',
                { number }
              ),
              isMulti: false,
              options: timeOptions,
            }}
          />
          <TextInput
            control={control}
            name={`closePositionParams.timeBasedParams.closeSteps.${index}.positionSizePercent`}
            componentProps={{
              disabled,
              type: 'number',
              label: t(
                'pages.createSession.timeBasedPositionParams.form.closeSteps.positionSizePercent.label',
                { number }
              ),
            }}
          />
        </div>
      ))}
      <SelectInput
        control={control}
        name="closePositionParams.timeBasedParams.closeAllPositionsDate"
        componentProps={{
          disabled,
          label: t(
            'pages.createSession.timeBasedPositionParams.form.closeAllPositionsDate'
          ),
          options: timeOptions,
        }}
      />
      <RadioGroupInput
        control={control}
        name="closePositionParams.timeBasedParams.orderType"
        componentProps={{
          disabled,
          label: t(
            'pages.createSession.timeBasedPositionParams.form.orderType.label'
          ),
          options: [
            {
              label: t(
                'pages.createSession.timeBasedPositionParams.form.orderType.marketOrder'
              ),
              value: CLOSE_POSITION_ORDER_TYPE.MARKET,
            },
            {
              label: t(
                'pages.createSession.timeBasedPositionParams.form.orderType.bidLimit'
              ),
              value: CLOSE_POSITION_ORDER_TYPE.BID,
            },
            {
              label: t(
                'pages.createSession.timeBasedPositionParams.form.orderType.askLimit'
              ),
              value: CLOSE_POSITION_ORDER_TYPE.ASK,
            },
          ],
        }}
      />
      <TextInput
        control={control}
        name="closePositionParams.timeBasedParams.limitOrderRefreshIntervalInSec"
        componentProps={{
          disabled,
          type: 'number',
          label: t(
            'pages.createSession.timeBasedPositionParams.form.limitOrderRefreshIntervalInSec'
          ),
        }}
      />
    </div>
  );
};

export default TimeBasedPositionClose;
