import React, { FC } from 'react';
import { UseControllerProps } from 'react-hook-form';

import Blacklist from './Blacklist';
import ClosingParameters from './ClosingParameters';
import TimeBasedPositionClose from './TimeBasedPositionClose';
import { CreateEditSessionFormData } from '../validationSchema';

export type CloseAndFilterParametersProps = Pick<
  UseControllerProps<CreateEditSessionFormData>,
  'control'
> & {
  disabled?: {
    closeParamsForm?: boolean;
    blacklist?: boolean;
  };
};

const CloseAndFilterParameters: FC<CloseAndFilterParametersProps> = ({
  control,
  disabled,
}) => {
  return (
    <div className="flex flex-col gap-6 md:flex-row">
      <div className="flex flex-1 flex-col gap-6">
        <ClosingParameters
          control={control}
          disabled={disabled?.closeParamsForm}
        />
        <TimeBasedPositionClose
          control={control}
          disabled={disabled?.closeParamsForm}
        />
      </div>
      <Blacklist control={control} disabled={disabled?.blacklist} />
    </div>
  );
};

export default CloseAndFilterParameters;
