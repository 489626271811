import { useFetch } from 'common/hooks';
import { ordersRepository } from 'common/repositories';

export type OrdersFetchArgs = Parameters<typeof ordersRepository.getOrders>[0];

export const useFetchOrdersWithTimer = (args: OrdersFetchArgs) => {
  const { data, ...hook } = useFetch(ordersRepository.getOrders, {
    instantFetch: true,
    args,
  });

  return { data: data?.data, ...hook };
};
