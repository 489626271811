// import React, { FC } from 'react';

// import { Table } from 'core/components/uikit';

// type JsonViewerProps = {
//   json: Record<string, unknown> | Array<unknown> | null;
// };

// const parseJsonToTable = (json: JsonViewerProps['json']) => {
//   if (!json) return { columns: [], data: [] };

//   if (Array.isArray(json)) {
//     const isPrimitiveArray = json.every(
//       (item) => typeof item !== 'object' || item === null
//     );

//     if (isPrimitiveArray) {
//       const columns = [
//         { accessorKey: 'key', header: 'Key' },
//         { accessorKey: 'value', header: 'Value' },
//       ];

//       const data = json.map((value, index) => ({
//         key: index,
//         value: value,
//       }));

//       return { columns, data };
//     }

//     const keys = Array.from(
//       new Set(
//         json.flatMap((item) =>
//           typeof item === 'object' && item !== null ? Object.keys(item) : []
//         )
//       )
//     );

//     const columns = keys.map((key) => ({
//       accessorKey: key,
//       header: key
//         .replace(/_/g, ' ')
//         .replace(/\b\w/g, (char) => char.toUpperCase()),
//       cell: ({ getValue }: { getValue: () => unknown }) => {
//         const value = getValue();
//         if (typeof value === 'object' && value !== null) {
//           const subTable = parseJsonToTable(value as Record<string, unknown>);
//           return (
//             <div className="border border-gray-300 p-2">
//               <Table
//                 paginate={false}
//                 ordinal={false}
//                 data={subTable.data}
//                 columns={subTable.columns}
//                 totalCount={subTable.data.length}
//                 options={{ manualPagination: false, state: {} }}
//               />
//             </div>
//           );
//         }
//         return String(value);
//       },
//     }));

//     const data = json.map((item) =>
//       typeof item === 'object' && item !== null ? item : { value: item }
//     );

//     return { columns, data };
//   }

//   // Объект
//   const columns = [
//     { accessorKey: 'key', header: 'Key' },
//     {
//       accessorKey: 'value',
//       header: 'Value',
//       cell: ({ getValue }: { getValue: () => unknown }) => {
//         const value = getValue();
//         if (typeof value === 'object' && value !== null) {
//           const subTable = parseJsonToTable(value as Record<string, unknown>);
//           return (
//             <div className="border border-gray-300 p-2">
//               <Table
//                 paginate={false}
//                 ordinal={false}
//                 data={subTable.data}
//                 columns={subTable.columns}
//                 totalCount={subTable.data.length}
//                 options={{ manualPagination: false, state: {} }}
//               />
//             </div>
//           );
//         }
//         return String(value);
//       },
//     },
//   ];

//   const data = Object.entries(json).map(([key, value]) => ({
//     key,
//     value,
//   }));

//   return { columns, data };
// };

// const JsonViewer: FC<JsonViewerProps> = ({ json }) => {
//   const { columns, data } = parseJsonToTable(json);

//   return (
//     <Table
//       data={data}
//       paginate={false}
//       ordinal={false}
//       columns={columns}
//       totalCount={data.length}
//       options={{ manualPagination: false, state: {} }}
//     />
//   );
// };

// export default JsonViewer;

import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

export type JsonTreeViewerProps = {
  data: unknown;
};

const JsonTreeViewerNode: FC<{ name?: string; value: unknown }> = ({
  name,
  value,
}) => {
  const isObject = typeof value === 'object' && value !== null;

  return (
    <li className="ml-4 list-none">
      {name && <span className="font-medium text-gray-700">{name}: </span>}
      {isObject ? (
        <JsonTreeViewer data={value} />
      ) : (
        <span className="text-gray-500">
          {value === null ? 'null' : JSON.stringify(value)}
        </span>
      )}
    </li>
  );
};

const JsonTreeViewer: FC<JsonTreeViewerProps> = ({ data }) => {
  const { t } = useTranslation();

  if (data === null || data === undefined) {
    return <p>{t('noData.general')}</p>;
  }

  if (Array.isArray(data)) {
    return (
      <ul className="border-l border-gray-300 pl-4">
        {data.map((value, index) => (
          <JsonTreeViewerNode key={index} name={`[${index}]`} value={value} />
        ))}
      </ul>
    );
  }

  if (typeof data === 'object') {
    return (
      <ul className="border-l border-gray-300 pl-4">
        {Object.entries(data).map(([key, value]) => (
          <JsonTreeViewerNode key={key} name={key} value={value} />
        ))}
      </ul>
    );
  }

  return <div className="text-gray-500">{JSON.stringify(data)}</div>;
};

export default JsonTreeViewer;
