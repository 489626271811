import React from 'react';
import { RouteObject, redirect } from 'react-router-dom';

import { Phone, PhoneVerify } from 'features';

import PATHS from '../paths';
import ProtectedRoute from '../ProtectedRoute';

const authRoutes: RouteObject = {
  path: PATHS.auth,
  children: [
    {
      index: true,
      loader: () => {
        return redirect(PATHS.phone);
      },
    },
    {
      path: PATHS.phoneKey,
      element: (
        <ProtectedRoute from="authUser">
          <Phone />
        </ProtectedRoute>
      ),
    },
    {
      path: PATHS.phoneVerifyKey,
      element: (
        <ProtectedRoute from="authUser">
          <PhoneVerify />
        </ProtectedRoute>
      ),
    },
  ],
};

export default authRoutes;
