import React, { FC, Fragment } from 'react';

import FilterInputs from './FilterInputs';
import OpenPositionInputs from './OpenPositionInputs';
import TriggerInputs from './TriggerInputs';
import { CommonInputsProps } from './types';

const CommonInputs: FC<CommonInputsProps> = ({
  control,
  basename,
  disabled = false,
}) => {
  return (
    <Fragment>
      <FilterInputs control={control} basename={basename} disabled={disabled} />
      <TriggerInputs
        control={control}
        basename={basename}
        disabled={disabled}
      />
      <OpenPositionInputs
        control={control}
        basename={basename}
        disabled={disabled}
      />
    </Fragment>
  );
};

export default CommonInputs;
