import React, { FC, useEffect, useState } from 'react';
import { RouterProvider } from 'react-router-dom';

import { useAuthStore, useSessionsStore } from 'common/stores';
import Splash from 'features/Splash';

import router from './router';

const useInitialApp = () => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchAll = async () => {
      try {
        const authStore = useAuthStore.getState();
        authStore.setTokenToHeadersFromStore();

        if (authStore.accessToken) {
          await useSessionsStore.getState().fetch();
        }
      } catch (err) {
        console.error(err);
      } finally {
        setIsLoading(false);
      }
    };

    fetchAll();
  }, []);

  return isLoading;
};

const App: FC = () => {
  const isLoading = useInitialApp();

  if (isLoading) {
    return <Splash />;
  }

  return <RouterProvider router={router} />;
};

export default App;
