import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
} from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/16/solid';
import React, { FC, Fragment, ReactNode } from 'react';

export type DisclosureWithTitleProps = {
  defaultOpen?: boolean;
  children: ReactNode;
  title: ReactNode;
  onClick?: (open: boolean) => void;
};

const DisclosureWithTitle: FC<DisclosureWithTitleProps> = ({
  defaultOpen,
  children,
  onClick,
  title,
}) => {
  return (
    <div className="flex flex-col gap-2">
      <Disclosure defaultOpen={defaultOpen}>
        {({ open }) => (
          <Fragment>
            <div className="flex cursor-pointer items-center justify-between gap-3">
              <DisclosureButton
                as="div"
                className="group flex items-center gap-2"
                onClick={() => onClick && onClick(open)}
              >
                <ChevronDownIcon className="w-5 group-data-[open]:rotate-180" />
                {title}
              </DisclosureButton>
            </div>
            <DisclosurePanel className="flex flex-col gap-2 pl-7">
              {children}
            </DisclosurePanel>
          </Fragment>
        )}
      </Disclosure>
    </div>
  );
};

export default DisclosureWithTitle;
