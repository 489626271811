import { clsx } from 'clsx';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import {
  withConfirmActionDialog,
  WithConfirmActionDialogSharedProps,
} from 'core/components/common';

import useSessionDetailsStore from '../sessionDetailsStore';

export type StartStopButtonProps = {
  disabled: boolean;
  isStarted: boolean;
  hasOpenPositions: boolean;
};

const StartStopButton: FC<
  WithConfirmActionDialogSharedProps & StartStopButtonProps
> = ({ disabled, isStarted, hasOpenPositions, showConfirmActionDialog }) => {
  const { t } = useTranslation();

  const handleOnClick = async () => {
    const store = useSessionDetailsStore.getState();

    const action = isStarted ? store.stopSession : store.startSession;

    await showConfirmActionDialog({
      onConfirm: action,
      title: t('pages.editSession.confrimDialog.title', {
        action: t(isStarted ? 'common.stop' : 'common.start').toLowerCase(),
      }),
      subtitle: hasOpenPositions
        ? t('pages.editSession.confrimDialog.subtitle')
        : undefined,
    });
  };

  return (
    <button
      onClick={handleOnClick}
      disabled={disabled}
      className={clsx(isStarted ? 'bg-yellow-500' : 'bg-green-500')}
    >
      {t(isStarted ? 'common.stop' : 'common.start')}
    </button>
  );
};

const StartStopButtonWithHoc = withConfirmActionDialog(StartStopButton);

export default StartStopButtonWithHoc;
