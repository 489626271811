import { CellContext, RowData } from '@tanstack/react-table';

import {
  formatDateWithTimezone,
  FormatDateWithTimezoneOptions,
} from 'common/utils';

export const valueCell =
  () =>
  <TData extends RowData>({ getValue }: CellContext<TData, unknown>) => {
    try {
      return getValue() || '-';
    } catch (error) {
      return '-';
    }
  };

export const enumCell =
  () =>
  <TData extends RowData>({ getValue }: CellContext<TData, unknown>) => {
    try {
      const value = getValue() as string | null;
      return value
        ? value
            .replace(/([A-Z])/g, ' $1')
            .trim()
            .toUpperCase()
        : '-';
    } catch (error) {
      return '-';
    }
  };

export const booleanCell =
  ({
    positive = 'Yes',
    negative = 'No',
  }: { positive?: string; negative?: string } = {}) =>
  <TData extends RowData>({ getValue }: CellContext<TData, unknown>) => {
    try {
      const value = getValue();
      if (value === true) {
        return positive;
      }

      if (value === false) {
        return negative;
      }

      return '-';
    } catch (error) {
      return '-';
    }
  };

export const dateCell =
  (options: FormatDateWithTimezoneOptions = {}) =>
  <TData extends RowData>({ getValue }: CellContext<TData, unknown>) => {
    try {
      const value = getValue();
      const formattedValue = value
        ? formatDateWithTimezone(value as Date, options)
        : '-';
      return formattedValue;
    } catch (error) {
      return '-';
    }
  };

export const numberCell =
  <TData extends RowData>({
    prefix = '',
    suffix = '',
  }: { suffix?: string; prefix?: string } = {}) =>
  ({ getValue }: CellContext<TData, unknown>) => {
    try {
      const value = getValue();
      return value ? `${prefix}${(value as number)?.toFixed(2)}${suffix}` : '-';
    } catch (error) {
      return '-';
    }
  };
