import { t } from 'i18next';
import React from 'react';
import {
  useController,
  FieldValues,
  UseControllerProps,
} from 'react-hook-form';
import * as yup from 'yup';

import { CheckboxGroup, CheckboxGroupProps } from '../uikit';

export const createCheckboxGroupInputSchema = ({
  name,
  required,
}: {
  name: string;
  required: boolean;
}) =>
  yup
    .array()
    .of(yup.string())
    .when([], {
      is: () => required,
      then: (schema) =>
        schema
          .required(t('errors.validation.isRequired', { name }))
          .min(1, t('errors.validation.isRequired', { name })),
      otherwise: (schema) =>
        schema
          .notRequired()
          .test(
            'optional-check',
            t('errors.validation.isRequired', { name }),
            (value) => !value || value.length === 0 || value.length >= 1
          ),
    });

export type CheckboxGroupInputProps<
  ValueT extends string,
  FormValues extends FieldValues,
> = UseControllerProps<FormValues> & {
  componentProps: CheckboxGroupProps<ValueT>;
};

const CheckboxGroupInput = <
  ValueT extends string,
  FormValues extends FieldValues,
>({
  componentProps,
  ...input
}: CheckboxGroupInputProps<ValueT, FormValues>) => {
  const { field, fieldState } = useController(input);

  const error = fieldState.error?.message;
  const { value, onChange } = field;

  return (
    <CheckboxGroup
      {...componentProps}
      error={error}
      values={value}
      onChange={onChange}
    />
  );
};

export default CheckboxGroupInput;
