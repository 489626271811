/* eslint-disable @typescript-eslint/no-explicit-any */
function transformEmptyObjectsToNull<T>(obj: T): T | null {
  if (Array.isArray(obj)) {
    const cleanedArray = obj
      .map((item) => transformEmptyObjectsToNull(item))
      .filter((item) => item !== null);

    return cleanedArray.length > 0 ? (cleanedArray as unknown as T) : null;
  } else if (typeof obj === 'object' && obj !== null) {
    // Create a copy of the object to ensure type safety
    const result: Record<string, any> = { ...obj };

    // Iterate over the properties of the object
    for (const key in result) {
      if (typeof result[key] === 'object' && result[key] !== null) {
        // If the property is an object, recursively clean it
        const cleanedValue = transformEmptyObjectsToNull(result[key]);

        if (cleanedValue === null) {
          result[key] = null;
        } else {
          result[key] = cleanedValue;
        }
      }
    }

    // If the object itself is empty after cleaning, return null
    if (Object.keys(result).every((key) => result[key] === null)) {
      return null;
    }

    return result as T;
  }

  return obj;
}

export default transformEmptyObjectsToNull;
