import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { EXCHANGE } from 'common/models/session';
import {
  CheckboxGroupInput,
  MinMaxInput,
  TextInput,
} from 'core/components/form';
import { DisclosureWithTitle } from 'features/components';

import { CommonInputsProps } from './types';

const FilterInputs: FC<CommonInputsProps> = ({
  control,
  basename,
  disabled = false,
}) => {
  const { t } = useTranslation();

  return (
    <DisclosureWithTitle
      defaultOpen={true}
      title={<h5>{t('pages.createSession.common.form.filterParams.title')}</h5>}
    >
      <CheckboxGroupInput
        control={control}
        name={`${basename}.filterParams.exchanges`}
        componentProps={{
          disabled,
          label: t(
            'pages.createSession.common.form.filterParams.exchanges.label'
          ),
          options: [
            {
              label: t(
                'pages.createSession.common.form.filterParams.exchanges.nasdaq'
              ),
              value: EXCHANGE.NASDAQ,
            },
            {
              label: t(
                'pages.createSession.common.form.filterParams.exchanges.nyse'
              ),
              value: EXCHANGE.NYSE,
            },
          ],
        }}
      />
      <MinMaxInput
        min={{
          control,
          name: `${basename}.filterParams.priceChangePercentFromPrevCloseRange.min`,
          componentProps: {
            label: t(
              'pages.createSession.common.form.filterParams.priceChangePercentFromPrevCloseRange.min'
            ),
            disabled,
          },
        }}
        max={{
          control,
          name: `${basename}.filterParams.priceChangePercentFromPrevCloseRange.max`,
          componentProps: {
            label: t(
              'pages.createSession.common.form.filterParams.priceChangePercentFromPrevCloseRange.max'
            ),
            disabled,
          },
        }}
      />
      <MinMaxInput
        min={{
          control,
          name: `${basename}.filterParams.marketCapRange.min`,
          componentProps: {
            label: t(
              'pages.createSession.common.form.filterParams.marketCapRange.min'
            ),
            disabled,
          },
        }}
        max={{
          control,
          name: `${basename}.filterParams.marketCapRange.max`,
          componentProps: {
            label: t(
              'pages.createSession.common.form.filterParams.marketCapRange.max'
            ),
            disabled,
          },
        }}
      />
      <MinMaxInput
        min={{
          control,
          name: `${basename}.filterParams.priceRange.min`,
          componentProps: {
            label: t(
              'pages.createSession.common.form.filterParams.priceRange.min'
            ),
            disabled,
          },
        }}
        max={{
          control,
          name: `${basename}.filterParams.priceRange.max`,
          componentProps: {
            label: t(
              'pages.createSession.common.form.filterParams.priceRange.max'
            ),
            disabled,
          },
        }}
      />
      <MinMaxInput
        min={{
          control,
          name: `${basename}.filterParams.currentDayVolumeIn$Range.min`,
          componentProps: {
            label: t(
              'pages.createSession.common.form.filterParams.currentDayVolumeIn$Range.min'
            ),
            disabled,
          },
        }}
        max={{
          control,
          name: `${basename}.filterParams.currentDayVolumeIn$Range.max`,
          componentProps: {
            label: t(
              'pages.createSession.common.form.filterParams.currentDayVolumeIn$Range.max'
            ),
            disabled,
          },
        }}
      />
      <MinMaxInput
        min={{
          control,
          name: `${basename}.filterParams.lastPeriodVolumeIn$Range.min`,
          componentProps: {
            label: t(
              'pages.createSession.common.form.filterParams.lastPeriodVolumeIn$Range.min'
            ),
            disabled,
          },
        }}
        max={{
          control,
          name: `${basename}.filterParams.lastPeriodVolumeIn$Range.max`,
          componentProps: {
            label: t(
              'pages.createSession.common.form.filterParams.lastPeriodVolumeIn$Range.max'
            ),
            disabled,
          },
        }}
      />
      <TextInput
        control={control}
        name={`${basename}.filterParams.lastPeriodInMinutes`}
        componentProps={{
          disabled,
          type: 'number',
          label: t(
            'pages.createSession.common.form.filterParams.lastPeriodInMinutes.label'
          ),
        }}
      />
    </DisclosureWithTitle>
  );
};

export default FilterInputs;
