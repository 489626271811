import { useCallback, useEffect, useRef, useState } from 'react';

export type UseFetchOptions<ArgumentsT = void> = {
  instantFetch?: boolean;
  args?: ArgumentsT;
};

export function useFetch<ReturnT, ArgumentsT>(
  apiCall: (args: ArgumentsT) => Promise<ReturnT>,
  options: UseFetchOptions<ArgumentsT>
): {
  isLoading: boolean;
  data: ReturnT | null;
  fetch: (args: ArgumentsT, shouldUpdateArgs?: boolean) => Promise<ReturnT>;
  setArgs: (args: ArgumentsT) => void;
  startTimer: (interval: number) => void;
  stopTimer: () => void;
  args: ArgumentsT | undefined;
};

export function useFetch<ReturnT>(
  apiCall: () => Promise<ReturnT>,
  options: UseFetchOptions<void>
): {
  isLoading: boolean;
  data: ReturnT | null;
  fetch: (shouldUpdateArgs?: boolean) => Promise<ReturnT>;
  startTimer: (interval: number) => void;
  stopTimer: () => void;
};

export function useFetch<ReturnT, ArgumentsT = void>(
  apiCall: (args?: ArgumentsT) => Promise<ReturnT>,
  { instantFetch = false, args: initialArgs }: UseFetchOptions<ArgumentsT> = {}
) {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<ReturnT | null>(null);
  const [args, setArgs] = useState<ArgumentsT | undefined>(() => initialArgs);
  const intervalIdRef = useRef<NodeJS.Timeout | null>(null);
  const timeRef = useRef<number | null>(null);

  const fetch = useCallback(
    async (currentArgs?: ArgumentsT, shouldUpdateArgs?: boolean) => {
      if (shouldUpdateArgs ?? true) {
        setArgs(currentArgs);
      }

      setIsLoading(true);
      try {
        const result = await apiCall(currentArgs);
        setData(result);
        return result;
      } finally {
        setIsLoading(false);
      }
    },
    [apiCall]
  );

  const stopTimer = useCallback(() => {
    if (intervalIdRef.current) {
      clearInterval(intervalIdRef.current);
      intervalIdRef.current = null;
    }
  }, []);

  const startTimer = useCallback(
    (interval: number) => {
      if (intervalIdRef.current) {
        stopTimer();
      }

      timeRef.current = interval;
      intervalIdRef.current = setInterval(() => {
        fetch(args, false);
      }, interval);
    },
    [args, fetch, stopTimer]
  );

  useEffect(() => {
    if (instantFetch) {
      fetch(initialArgs, false);
    }

    return stopTimer;
  }, []);

  // Restart timer when args changed
  useEffect(() => {
    if (intervalIdRef.current) {
      startTimer(timeRef.current ?? 10000);
    }
  }, [startTimer]);

  return {
    isLoading,
    data,
    fetch,
    setArgs,
    startTimer,
    stopTimer,
    args,
  };
}
