import React, { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { CircularIndicator } from 'core/components/uikit';
import { DisclosureWithTitle } from 'features/components';

import TickerTable from './TickerTable';
import { useFetchSessionTickers } from './useFetchSessionTickers';

export type SessionTickersProps = {
  sessionId: string;
};

const SessionTickers: FC<SessionTickersProps> = ({ sessionId }) => {
  const { t } = useTranslation();
  const { data, isLoading, startTimer, stopTimer } = useFetchSessionTickers({
    sessionId,
  });

  const { rthMarket, ahMarket, phMarket } = data ?? {};

  const toogleTimer = useCallback(
    (open: boolean) => {
      if (open) {
        stopTimer();
      } else {
        startTimer(5000);
      }
    },
    [stopTimer, startTimer]
  );

  return (
    <DisclosureWithTitle
      title={
        <div className="flex flex-row items-center gap-2">
          <h4>{t('components.tickersTable.title')}</h4>
          {isLoading && <CircularIndicator size={24} />}
        </div>
      }
      onClick={toogleTimer}
    >
      <div className="flex flex-col gap-4">
        <DisclosureWithTitle
          title={<h4>{t('components.tickersTable.titles.prehours')}</h4>}
        >
          <TickerTable data={phMarket ?? []} />
        </DisclosureWithTitle>
        <DisclosureWithTitle
          title={<h4>{t('components.tickersTable.titles.rth')}</h4>}
        >
          <TickerTable data={rthMarket ?? []} />
        </DisclosureWithTitle>
        <DisclosureWithTitle
          title={<h4>{t('components.tickersTable.titles.afterhours')}</h4>}
        >
          <TickerTable data={ahMarket ?? []} />
        </DisclosureWithTitle>
      </div>
    </DisclosureWithTitle>
  );
};

export default SessionTickers;
