import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import {
  DisclosureWithTitle,
  SessionStatuses,
  SessionStatusesProps,
} from 'features/components';

const Statuses: FC<SessionStatusesProps> = (props) => {
  const { t } = useTranslation();

  return (
    <DisclosureWithTitle
      defaultOpen={true}
      title={<h4>{t('pages.editSession.statuses.title')}</h4>}
    >
      <SessionStatuses {...props} />
    </DisclosureWithTitle>
  );
};

export default Statuses;
