import { AxiosResponse } from 'axios';

import {
  DeprecatedSession,
  PaginatedResult,
  Session,
  SessionFilters,
  SessionTickers,
} from 'common/models';
import http from 'core/services/api';

import { SESSION_TICKERS, DEPRECATED_SESSIONS } from './mockData';

class SessionsRepository {
  getSessionsMocked = async () =>
    http.fetch(async () => {
      await new Promise((resolve) => setTimeout(resolve, 1000));

      return {
        data: DEPRECATED_SESSIONS,
        status: 200,
        statusText: '200',
        headers: {},
        config: {},
      } as AxiosResponse<DeprecatedSession[]>;
    });

  getSessionByIdMocked = async ({ sessionId }: { sessionId: string }) =>
    http.fetch(async () => {
      await new Promise((resolve) => setTimeout(resolve, 1000));

      const sessions = DEPRECATED_SESSIONS.filter(
        (item) => item.sessionId === sessionId
      );
      const data = sessions.length === 0 ? null : sessions[0];

      return {
        data,
        status: 200,
        statusText: '200',
        headers: {},
        config: {},
      } as AxiosResponse<DeprecatedSession | null>;
    });

  getSessionTickersByIdMocked = async ({ sessionId }: { sessionId: string }) =>
    http.fetch(async () => {
      await new Promise((resolve) => setTimeout(resolve, 1000));

      const data = SESSION_TICKERS[sessionId];

      return {
        data,
        status: 200,
        statusText: '200',
        headers: {},
        config: {},
      } as AxiosResponse<SessionTickers | null>;
    });

  getSessions = async ({
    page = 1,
    perPage = 20,
    ...params
  }: SessionFilters | undefined = {}) =>
    http.fetch(async (axios, signal) => {
      return axios.get<PaginatedResult<Session>>(`/strategies/filter`, {
        signal,
        params: {
          page,
          perPage,
          ...params,
        },
      });
    });

  getSessionTickersById = async ({ sessionId = '0' }: { sessionId: string }) =>
    http.fetch(async (axios, signal) => {
      return axios.get<SessionTickers>(`/strategies/${sessionId}/tickers`, {
        signal,
      });
    });

  getSessionById = async ({ sessionId }: { sessionId: string }) =>
    http.fetch(async (axios, signal) => {
      return axios.get<Session>(`/strategies/${sessionId ?? '0'}`, {
        signal,
      });
    });
}

export const sessionsRepository = new SessionsRepository();
