import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import {
  DeprecatedCreateSessionBody,
  DEPRECATED_EXCHANGE,
} from 'common/models';
import { useSessionsStore } from 'common/stores';
import {
  withConfirmActionDialog,
  WithConfirmActionDialogSharedProps,
  Wrapper,
} from 'core/components/common';
import { PATHS } from 'core/router';

import CreateEditSessionForm, {
  CreateEditSessionFormProps,
} from '../CreateEditSessionForm';

const defaultValues: CreateEditSessionFormProps['defaultValues'] = {
  phParams: {
    active: false,
    marketHoursRange: ['4:00 AM', '9:30 AM'],
    filterParams: {
      exchanges: [DEPRECATED_EXCHANGE.NASDAQ, DEPRECATED_EXCHANGE.NYSE],
    },
  },
  rthParams: {
    active: false,
    marketHoursRange: ['9:30 AM', '4:00 PM'],
    filterParams: {
      exchanges: [DEPRECATED_EXCHANGE.NASDAQ, DEPRECATED_EXCHANGE.NYSE],
    },
  },
  ahParams: {
    active: false,
    marketHoursRange: ['4:00 PM', '8:00 PM'],
    filterParams: {
      exchanges: [DEPRECATED_EXCHANGE.NASDAQ, DEPRECATED_EXCHANGE.NYSE],
    },
  },
};

const CreateSession: FC<WithConfirmActionDialogSharedProps> = ({
  showConfirmActionDialog,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const onSubmit: CreateEditSessionFormProps['onSubmit'] = async (values) =>
    showConfirmActionDialog(async () => {
      const newSession = await useSessionsStore
        .getState()
        .createSession(values as DeprecatedCreateSessionBody);

      if (newSession !== null) {
        navigate(PATHS.sessions);
      }
    });

  return (
    <Wrapper internalClassName="flex flex-col">
      <h2 className="mb-6">{t('pages.createSession.title')}</h2>
      <CreateEditSessionForm
        onSubmit={onSubmit}
        actionButtonText={t('pages.createSession.action')}
        defaultValues={defaultValues}
      />
    </Wrapper>
  );
};

const CreateSessionWithHoc = withConfirmActionDialog(CreateSession);

export default CreateSessionWithHoc;
