import { Nullable } from 'common/types';
import { RangeValue } from 'core/components/form';

import { Paginated, Sorted } from './paginated';

export type TTime = `${number}:${number} ${'AM' | 'PM'}`;
export type TDuration = `${number}${'s' | 'm' | 'h' | 'd'}`;

export const EXCHANGE = {
  NASDAQ: 'XNAS',
  NYSE: 'XNYS',
} as const;
export type Exchange = (typeof EXCHANGE)[keyof typeof EXCHANGE];

export type FilterParams = {
  priceChangePercentFromPrevCloseRange?: [number, number]; // TODO: change from MinMaxValue to [number, number]
  currentDayVolumeIn$Range?: [number, number]; // TODO: change from MinMaxValue to [number, number]
  lastPeriodVolumeIn$Range?: [number, number]; // TODO: change from MinMaxValue to [number, number]
  lastPeriodInMinutes?: number;
  marketCapRange?: [number, number]; // TODO: change from MinMaxValue to [number, number]
  priceRange?: [number, number]; // TODO: change from MinMaxValue to [number, number]
  exchanges: Exchange[];
};

export type TriggerParams = {
  lastPeriodInMinutes: number;
  lastPeriodPriceChangePercent: number;
  priceChangePercentFromPrevClose: number;
};

export type StopLossParams = {
  sizeOfClosingPositionPart: number;
  triggeringPriceChange: number;
};

export type TakeProfitParams = {
  sizeOfClosingPositionPart: number;
  triggeringPriceChange: number;
};

export type TimeBasedClosePositionParams = {
  sizeOfClosingPositionPart: number;
  triggeringPriceChange: number;
};

// TODO: make part of market params. Change in form. Now every timeperiod has close params.
export type ClosePositionParams = Nullable<{
  stopLossParams: StopLossParams[];
  takeProfitParams: TakeProfitParams[];
  timeBasedParams: TimeBasedClosePositionParams[];
}>;

export const OPEN_POSITION_ORDER_TYPE = {
  MARKET: 'market',
  ASK: 'ask-.01',
  BID: 'bid',
} as const;
export type OpenPositionOrderType =
  (typeof OPEN_POSITION_ORDER_TYPE)[keyof typeof OPEN_POSITION_ORDER_TYPE];

export const CLOSE_POSITION_ORDER_TYPE = {
  MARKET: 'market',
  BID: 'bid+.01',
  ASK: 'ask',
} as const;
export type ClosePositionOrderType =
  (typeof CLOSE_POSITION_ORDER_TYPE)[keyof typeof CLOSE_POSITION_ORDER_TYPE];

export type AdditionalStepItem = {
  // TODO: change in form.
  // prev values:
  // positionSize: number;
  // increaseFromInitialBuy: number;
  triggeringPriceChange: number;
  entrySize: number;
};

export type OpenPositionParams = {
  maxPositionVolume: number; // TODO: need to add to form?
  maxEntryVolume: number; // TODO: need to add to form?
  openEntryInterval: TDuration; // TODO: need to change in form?
  orderType: OpenPositionOrderType;
  orderPriceType: string; // TODO: check type and add to form
  orderRefreshInterval: TDuration; // TODO: change in form. prev: limitOrderRefreshIntervalInSec
  openEntryLimit: TDuration; // TODO: change in form. prev: entryIntervalInSec
  chaseDown: number;
  additionalSteps: AdditionalStepItem[]; // TODO: change from additionalPositions to additionalSteps in form
};

export type MarketParams = Nullable<{
  active: boolean;
  marketHoursRange: RangeValue;
  filterParams: FilterParams;
  triggerParams: TriggerParams;
  openPositionParams: OpenPositionParams;
  closePositionParams: ClosePositionParams;
}>;

export type CreateSessionBody = {
  sessionName?: string;
  rthMarket: MarketParams;
  phMarket: MarketParams;
  ahMarket: MarketParams;
  closePositionParams?: ClosePositionParams;
  blacklist?: string[];
};

export const MARKET_STATUS = {
  PH_MARKET: 'phMarket',
  AH_MARKET: 'ahMarket',
  RTH_MARKET: 'rthMarket',
  CLOSED: 'closed',
} as const;
export type MarketStatus = (typeof MARKET_STATUS)[keyof typeof MARKET_STATUS];

export const SESSION_STATUS = {
  ERROR: 'error',
  ACTIVE: 'active',
  STOPPED: 'stopped',
} as const;
export type SessionStatus =
  (typeof SESSION_STATUS)[keyof typeof SESSION_STATUS];

export const SESSION_SEARCH_MONITOR_STATUS = {
  ERROR: 'error',
  PAUSED: 'paused',
  STOPPED: 'stopped',
  PH_MARKET: 'phMarket',
  AH_MARKET: 'ahMarket',
  RTH_MARKET: 'rthMarket',
} as const;
export type SessionSearchStatus =
  (typeof SESSION_SEARCH_MONITOR_STATUS)[keyof typeof SESSION_SEARCH_MONITOR_STATUS];
export type SessionMonitorStatus =
  (typeof SESSION_SEARCH_MONITOR_STATUS)[keyof typeof SESSION_SEARCH_MONITOR_STATUS];

export type Session = Nullable<{
  sessionId: string;
  sessionName: string;
  strategyName: string;
  rthMarket: MarketParams;
  phMarket: MarketParams;
  ahMarket: MarketParams;
  blacklist: string[];
  status: SessionStatus;
  marketStatus: MarketStatus;
  searchStatus: SessionSearchStatus;
  monitorStatus: SessionMonitorStatus;
}>;

export type EditSessionBody = {
  sessionId: string;
  body: CreateSessionBody;
};

export const ORDER_BY_SESSION_FILTER = {
  CREATEDAT: 'createdAt',
  STATUS: 'status',
  SEARCH_STATUS: 'searchStatus',
  MONITOR_STATUS: 'monitorStatus',
  SESSION_NAME: 'sessionName',
  STRATEGY_NAME: 'strategyName',
} as const;
export type OrderBySessionFilter =
  (typeof ORDER_BY_SESSION_FILTER)[keyof typeof ORDER_BY_SESSION_FILTER];

export type SessionFilters = Paginated &
  Sorted<OrderBySessionFilter> &
  Partial<{
    status: SessionStatus;
  }>;
