import { XMarkIcon } from '@heroicons/react/24/outline';
import React, { FC } from 'react';
import { useFieldArray } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { OPEN_POSITION_ORDER_TYPE } from 'common/models/session';
import { RadioGroupInput, TextInput } from 'core/components/form';
import { DisclosureWithTitle } from 'features/components';

import { CommonInputsProps } from './types';

const OpenPositionInputs: FC<CommonInputsProps> = ({
  control,
  basename,
  disabled = false,
}) => {
  const { t } = useTranslation();

  return (
    <DisclosureWithTitle
      title={
        <h5>{t('pages.createSession.common.form.openPositionParams.title')}</h5>
      }
      defaultOpen={true}
    >
      <TextInput
        control={control}
        name={`${basename}.openPositionParams.positionSizeIn$`}
        componentProps={{
          disabled,
          type: 'number',
          label: t(
            'pages.createSession.common.form.openPositionParams.positionSizeIn$.label'
          ),
        }}
      />
      <TextInput
        control={control}
        name={`${basename}.openPositionParams.entriesCount`}
        componentProps={{
          disabled,
          type: 'number',
          label: t(
            'pages.createSession.common.form.openPositionParams.entriesCount.label'
          ),
        }}
      />
      <TextInput
        control={control}
        name={`${basename}.openPositionParams.entryIntervalInSec`}
        componentProps={{
          disabled,
          type: 'number',
          label: t(
            'pages.createSession.common.form.openPositionParams.entryIntervalInSec.label'
          ),
        }}
      />
      <RadioGroupInput
        control={control}
        name={`${basename}.openPositionParams.orderType`}
        componentProps={{
          disabled,
          label: t(
            'pages.createSession.common.form.openPositionParams.orderType.label'
          ),
          options: [
            {
              label: t(
                'pages.createSession.common.form.openPositionParams.orderType.marketOrder'
              ),
              value: OPEN_POSITION_ORDER_TYPE.MARKET,
            },
            {
              label: t(
                'pages.createSession.common.form.openPositionParams.orderType.askLimit'
              ),
              value: OPEN_POSITION_ORDER_TYPE.ASK,
            },
            {
              label: t(
                'pages.createSession.common.form.openPositionParams.orderType.bidLimit'
              ),
              value: OPEN_POSITION_ORDER_TYPE.BID,
            },
          ],
        }}
      />
      <TextInput
        control={control}
        name={`${basename}.openPositionParams.limitOrderRefreshIntervalInSec`}
        componentProps={{
          disabled,
          type: 'number',
          label: t(
            'pages.createSession.common.form.openPositionParams.limitOrderRefreshIntervalInSec.label'
          ),
        }}
      />
      <TextInput
        control={control}
        name={`${basename}.openPositionParams.chaseDown`}
        componentProps={{
          disabled,
          type: 'number',
          label: t(
            'pages.createSession.common.form.openPositionParams.chaseDown.label'
          ),
        }}
      />
      <AdditionalPositionsInputs {...{ control, basename, disabled }} />
    </DisclosureWithTitle>
  );
};

const AdditionalPositionsInputs: FC<CommonInputsProps> = ({
  control,
  basename,
  disabled = false,
}) => {
  const { t } = useTranslation();

  const { fields, append, remove } = useFieldArray({
    control,
    name: `${basename}.openPositionParams.additionalPositions`,
  });

  return (
    <div>
      {fields.map((field, index) => (
        <div key={field.id} className="mb-4 flex items-center gap-3">
          <button
            onClick={() => remove(index)}
            disabled={disabled}
            className="h-full bg-red-500 p-2"
          >
            <XMarkIcon className="size-5" />
          </button>
          <div className="flex w-full gap-3">
            <TextInput
              control={control}
              name={`${basename}.openPositionParams.additionalPositions.${index}.positionSize`}
              componentProps={{
                disabled,
                type: 'number',
                label: t(
                  'pages.createSession.common.form.openPositionParams.additionalPositions.positionSize.label',
                  { number: index + 1 }
                ),
              }}
            />
            <TextInput
              name={`${basename}.openPositionParams.additionalPositions.${index}.increaseFromInitialBuy`}
              control={control}
              componentProps={{
                disabled,
                type: 'number',
                label: t(
                  'pages.createSession.common.form.openPositionParams.additionalPositions.increaseFromInitialBuy.label',
                  { number: index + 1 }
                ),
              }}
            />
          </div>
        </div>
      ))}
      {fields.length < 10 && (
        <button
          type="button"
          onClick={() =>
            append({
              positionSize: undefined,
              increaseFromInitialBuy: undefined,
            })
          }
          className="outlined-button-s mt-2 w-full"
          disabled={disabled}
        >
          {t(
            'pages.createSession.common.form.openPositionParams.additionalPositions.add'
          )}
        </button>
      )}
    </div>
  );
};

export default OpenPositionInputs;
