import React, { FC } from 'react';

type CircularIndicatorProps = {
  size?: number;
  color?: string;
  strokeWidth?: number;
};

const CircularIndicator: FC<CircularIndicatorProps> = ({
  size = 32,
  strokeWidth = 3,
  color = 'text-gray-400',
}) => {
  const radius = (size - strokeWidth * 2) / 2;
  const circumference = 2 * Math.PI * radius;

  return (
    <div className="flex items-center justify-center">
      <svg
        className="animate-spin-linear"
        width={size}
        height={size}
        viewBox={`0 0 ${size} ${size}`}
      >
        <circle
          className={`stroke-current ${color}`}
          cx={size / 2}
          cy={size / 2}
          r={radius}
          fill="none"
          strokeWidth={strokeWidth}
          strokeDasharray={circumference}
          strokeDashoffset={circumference / 2}
        />
      </svg>
    </div>
  );
};

export default CircularIndicator;
