import React, { useState, ComponentType } from 'react';

import { AsyncCb } from 'common/types';

import ConfirmActionDialog, {
  ConfirmActionDialogProps,
} from './ConfirmActionDialog';

type DialogState = {
  isOpen: boolean;
  onConfirm: AsyncCb | null;
} & Pick<ConfirmActionDialogProps, 'subtitle' | 'title'>;

export type WithConfirmActionDialogSharedProps = {
  showConfirmActionDialog: (
    state: Omit<DialogState, 'isOpen'> | AsyncCb
  ) => Promise<void>;
};

function withConfirmActionDialog<T extends WithConfirmActionDialogSharedProps>(
  WrappedComponent: ComponentType<T>
) {
  const EnhancedComponent = (
    props: Omit<T, keyof WithConfirmActionDialogSharedProps>
  ) => {
    const [{ isOpen, onConfirm, title, subtitle }, setDialogState] =
      useState<DialogState>({
        isOpen: false,
        onConfirm: null,
        title: undefined,
        subtitle: undefined,
      });

    const openDialog: WithConfirmActionDialogSharedProps['showConfirmActionDialog'] =
      async (params) => {
        const dialogConfig =
          typeof params === 'function' ? { onConfirm: params } : params;

        setDialogState({
          isOpen: true,
          ...dialogConfig,
        });
      };

    const hanldeClose = () => {
      setDialogState({
        isOpen: false,
        onConfirm: null,
        title: undefined,
        subtitle: undefined,
      });
    };

    const handleConfirm = async () => {
      if (onConfirm) await onConfirm();
      hanldeClose();
    };

    return (
      <>
        <WrappedComponent
          {...(props as T)}
          showConfirmActionDialog={openDialog}
        />
        <ConfirmActionDialog
          title={title}
          subtitle={subtitle}
          isOpen={isOpen}
          onClose={hanldeClose}
          onConfirm={handleConfirm}
        />
      </>
    );
  };

  EnhancedComponent.displayName = `withConfirmActionDialog(${WrappedComponent.displayName || WrappedComponent.name || 'Component'})`;

  return EnhancedComponent;
}

export default withConfirmActionDialog;
