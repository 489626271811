import { t } from 'i18next';
import React, { ChangeEventHandler, useState } from 'react';
import { FieldValues, useController } from 'react-hook-form';
import * as yup from 'yup';

import { TextInput, TextInputProps } from 'core/components/uikit';

import { DefaultFormInputProps } from './types';

const phoneRegExp = /^\d{10}$/;

export const phoneSchema = yup
  .string()
  .required(
    t('errors.validation.isRequired', {
      name: t('pages.phone.fields.phone'),
    })
  )
  .matches(
    phoneRegExp,
    t('errors.validation.isInvalid', {
      name: t('pages.phone.fields.phone'),
    })
  );

const FormattedPhoneInput = <FormValues extends FieldValues>({
  componentProps,
  ...input
}: DefaultFormInputProps<FormValues, TextInputProps>) => {
  const [formattedPhone, setFormattedPhone] = useState('');
  const { field, fieldState } = useController(input);

  const error = fieldState.error?.message;

  const getFormatPhoneNumber = (value: string): string => {
    const phoneNumber = value.replace(/[^\d]/g, '');
    const phoneNumberLength = phoneNumber.length;

    if (phoneNumberLength < 4) return phoneNumber;
    if (phoneNumberLength < 7) {
      return `${phoneNumber.slice(0, 3)}-${phoneNumber.slice(3)}`;
    }
    return `${phoneNumber.slice(0, 3)}-${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`;
  };

  const { onChange, ...otherField } = field;

  const handlePhoneChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    const phone = e.currentTarget.value;
    const formattedPhone = getFormatPhoneNumber(phone);
    const numericValue = formattedPhone.replace(/-/g, '');
    setFormattedPhone(formattedPhone);
    onChange(numericValue);
  };

  return (
    <TextInput
      type="tel"
      error={error}
      placeholder="999-999-9999"
      {...componentProps}
      {...otherField}
      value={formattedPhone}
      onChange={handlePhoneChange}
    />
  );
};

export default FormattedPhoneInput;
