import { StateStorage } from 'zustand/middleware';

class StorageService implements StateStorage {
  LANGUAGE_KEY = 'LANGUAGE_KEY';
  AUTH_STORE_KEY = 'AUTH_STORE_KEY';

  getItem(key: string): string | null {
    return localStorage.getItem(key);
  }

  setItem(key: string, value: string): void {
    localStorage.setItem(key, value);
  }

  removeItem(key: string): void {
    localStorage.removeItem(key);
  }
}

const storage = new StorageService();

export default storage;
