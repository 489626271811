import { ActionFilter, PaginatedResult, Action } from 'common/models';
import http from 'core/services/api';

class ActionsRepository {
  getActions = async ({
    type,
    page = 1,
    sessionId,
    perPage = 10,
  }: ActionFilter) =>
    http.fetch(async (axios, signal) => {
      return axios.get<PaginatedResult<Action>>(`/actions/filter`, {
        signal,
        params: {
          sessionId,
          perPage,
          type,
          page,
        },
      });
    });
}

export const actionsRepository = new ActionsRepository();
