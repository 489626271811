import React, { FC } from 'react';
import { UseControllerProps } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
  formatedTimeToMinutesFromStartOfDay,
  minutesFromStartOfDayToFormatedTime,
} from 'common/utils';
import { RangeInput, SwitchInput } from 'core/components/form';

import CommonInputs from './CommonInputs';
import { CreateEditSessionFormData } from '../validationSchema';

export type RthParametersProps = Pick<
  UseControllerProps<CreateEditSessionFormData>,
  'control'
> & {
  disabled?: boolean;
};

const RthParameters: FC<RthParametersProps> = ({ control, disabled }) => {
  const { t } = useTranslation();

  return (
    <div className="flex flex-1 flex-col gap-3">
      <div className="flex items-center justify-between gap-3">
        <h4>{t('pages.createSession.rth.title')}</h4>
        <SwitchInput
          control={control}
          name="rthParams.active"
          componentProps={{ disabled }}
        />
      </div>
      <RangeInput
        componentProps={{
          label: t('pages.createSession.rth.form.marketHoursRange.label'),
          min: '9:30 AM',
          max: '4:00 PM',
          step: 30,
          disabled,
          renderLabel: minutesFromStartOfDayToFormatedTime,
          parseValue: formatedTimeToMinutesFromStartOfDay,
          formatValue: minutesFromStartOfDayToFormatedTime,
        }}
        control={control}
        name="rthParams.marketHoursRange"
      />
      <CommonInputs
        control={control}
        disabled={disabled}
        basename="rthParams"
      />
    </div>
  );
};

export default RthParameters;
