import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { PATHS } from 'core/router';

const AppLogo = () => {
  const { t } = useTranslation();

  return (
    <Link to={PATHS.sessions}>
      <h4>{t('components.appLogo')}</h4>
    </Link>
  );
};

export default AppLogo;
