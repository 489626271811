import { Option } from 'common/types';

const generateTimeOptions = (interval: number = 30): Option[] => {
  const options: Option[] = [];
  for (let hour = 0; hour < 24; hour++) {
    for (let minutes = 0; minutes < 60; minutes += interval) {
      const suffix = hour < 12 ? 'AM' : 'PM';
      const hour12 = hour % 12 || 12;
      const time = `${String(hour12).padStart(2, '0')}:${String(minutes).padStart(2, '0')} ${suffix}`;
      options.push({ value: time, label: time });
    }
  }
  return options;
};

export default generateTimeOptions;
