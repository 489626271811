import { Nullable } from 'common/types';
import { MinMaxValue, RangeValue } from 'core/components/form';

import { Position } from './position';

export type DeprecatedWatchlist = Nullable<{
  rthours: string[];
  prehours: string[];
  afterhours: string[];
}>;

export const DEPRECATED_EXCHANGE = {
  NASDAQ: 'XNAS',
  NYSE: 'XNYS',
} as const;
export type DeprecatedExchange =
  (typeof DEPRECATED_EXCHANGE)[keyof typeof DEPRECATED_EXCHANGE];

export type DeprecatedFilterParams = {
  priceChangePercentFromPrevCloseRange?: MinMaxValue;
  currentDayVolumeIn$Range?: MinMaxValue;
  lastPeriodVolumeIn$Range?: MinMaxValue;
  lastPeriodInMinutes?: number;
  marketCapRange?: MinMaxValue;
  priceRange?: MinMaxValue;
  exchanges: DeprecatedExchange[];
};

export type DeprecatedTriggerParams = {
  lastPeriodInMinutes: number;
  lastPeriodPriceChangePercent: number;
  priceChangePercentFromPrevClose: number;
};

export const DEPRECATED_OPEN_POSITION_ORDER_TYPE = {
  MARKET: 'market',
  ASK: 'ask-.01',
  BID: 'bid',
} as const;
export type DeprecatedOpenPositionOrderType =
  (typeof DEPRECATED_OPEN_POSITION_ORDER_TYPE)[keyof typeof DEPRECATED_OPEN_POSITION_ORDER_TYPE];

export const DEPRECATED_CLOSE_POSITION_ORDER_TYPE = {
  MARKET: 'market',
  BID: 'bid+.01',
  ASK: 'ask',
} as const;
export type DeprecatedClosePositionOrderType =
  (typeof DEPRECATED_CLOSE_POSITION_ORDER_TYPE)[keyof typeof DEPRECATED_CLOSE_POSITION_ORDER_TYPE];

export type DeprecatedAdditionalPositionItem = {
  positionSize: number;
  increaseFromInitialBuy: number;
};

export type DeprecatedOpenPositionParams = {
  limitOrderRefreshIntervalInSec: number;
  orderType: DeprecatedOpenPositionOrderType;
  entryIntervalInSec: number;
  positionSizeIn$: number;
  entriesCount: number;
  chaseDown: number;
  additionalPositions: DeprecatedAdditionalPositionItem[];
};

export type DeprecatedMarketParams = Nullable<{
  active: boolean;
  marketHoursRange: RangeValue;
  filterParams: DeprecatedFilterParams;
  triggerParams: DeprecatedTriggerParams;
  openPositionParams: DeprecatedOpenPositionParams;
}>;

export type DeprecatedStopLossParams = {
  positionSize: number;
  priceChangePercent: number;
};

export type DeprecatedTakeProfitParams = {
  priceChangePercent: number;
};

export type DeprecatedTimeBasedClosePositionParams = {
  date: string;
  positionSizePercent: number;
};

export type DeprecatedClosePositionParams = Nullable<{
  stopLossSteps: DeprecatedStopLossParams[];
  takeProfitSteps: DeprecatedTakeProfitParams[];
  timeBasedParams: {
    closeAllPositionsDate: string;
    orderType: DeprecatedClosePositionOrderType;
    limitOrderRefreshIntervalInSec: number;
    closeSteps: DeprecatedTimeBasedClosePositionParams[];
  };
}>;

export type DeprecatedCreateSessionBody = {
  sessionName?: string;
  rthParams: DeprecatedMarketParams;
  phParams: DeprecatedMarketParams;
  ahParams: DeprecatedMarketParams;
  closePositionParams?: DeprecatedClosePositionParams;
  blacklist?: string[];
};

export const DEPRECATED_MARKET_STATUS = {
  PH_MARKET: 'phMarket',
  AH_MARKET: 'ahMarket',
  RTH_MARKET: 'rthMarket',
  CLOSED: 'closed',
} as const;
export type DeprecatedMarketStatus =
  (typeof DEPRECATED_MARKET_STATUS)[keyof typeof DEPRECATED_MARKET_STATUS];

export const DEPRECATED_SESSION_STATUS = {
  ERROR: 'error',
  ACTIVE: 'active',
  STOPPED: 'stopped',
} as const;
export type DeprecatedSessionStatus =
  (typeof DEPRECATED_SESSION_STATUS)[keyof typeof DEPRECATED_SESSION_STATUS];

export const DEPRECATED_SESSION_SEARCH_MONITOR_STATUS = {
  ERROR: 'error',
  PAUSED: 'paused',
  STOPPED: 'stopped',
  PH_MARKET: 'phMarket',
  AH_MARKET: 'ahMarket',
  RTH_MARKET: 'rthMarket',
} as const;
export type DeprecatedSessionSearchStatus =
  (typeof DEPRECATED_SESSION_SEARCH_MONITOR_STATUS)[keyof typeof DEPRECATED_SESSION_SEARCH_MONITOR_STATUS];
export type DeprecatedSessionMonitorStatus =
  (typeof DEPRECATED_SESSION_SEARCH_MONITOR_STATUS)[keyof typeof DEPRECATED_SESSION_SEARCH_MONITOR_STATUS];

export type DeprecatedSession = Nullable<{
  sessionId: string;
  sessionName: string;
  rthParams: DeprecatedMarketParams;
  phParams: DeprecatedMarketParams;
  ahParams: DeprecatedMarketParams;
  closePositionParams: DeprecatedClosePositionParams;
  blacklist: string[];
  status: DeprecatedSessionStatus;
  marketStatus: DeprecatedMarketStatus;
  searchStatus: DeprecatedSessionSearchStatus;
  monitorStatus: DeprecatedSessionMonitorStatus;
  lastActions: string[];
  watchlist: DeprecatedWatchlist;
  positions: Position[];
}>;

export type DeprecatedEditSessionBody = {
  sessionId: string;
  body: DeprecatedCreateSessionBody;
};
