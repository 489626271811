import { yupResolver } from '@hookform/resolvers/yup';
import { enqueueSnackbar } from 'notistack';
import React, { FC } from 'react';
import { useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';

import { OtpCodeInput } from 'core/components/form';
import { CircularIndicator } from 'core/components/uikit';

import { PhoneVerifyFormData, phoneVerifySchema } from './validationSchema';

export type PhoneVerifyFormProps = {
  phone: string;
  onSubimit: (values: PhoneVerifyFormData) => Promise<void>;
};

const PhoneVerifyForm: FC<PhoneVerifyFormProps> = ({ onSubimit }) => {
  const { t } = useTranslation();

  const {
    control,
    handleSubmit,
    formState: { isValid, isSubmitting },
  } = useForm<PhoneVerifyFormData>({
    resolver: yupResolver(phoneVerifySchema),
    mode: 'all',
    defaultValues: { code: '' },
  });

  const isButtonDisabled = !isValid || isSubmitting;

  const onSubmit = handleSubmit(onSubimit);

  const resendCode = async () => {
    // const success = await useAuthStore.getState().resendPhoneCode({ phone });

    // if (!success) return;

    enqueueSnackbar(t('notify.resendCode'), {
      variant: 'success',
      hideIconVariant: true,
    });
  };

  return (
    <form className="flex flex-col" onSubmit={onSubmit}>
      <OtpCodeInput name="code" control={control} />
      <button type="submit" className="mb-4" disabled={isButtonDisabled}>
        {isSubmitting ? <CircularIndicator /> : t('pages.verifyPhone.action')}
      </button>
      <p className="text-center text-[12px]">
        <Trans
          i18nKey="pages.verifyPhone.helperText"
          components={{
            button: (
              <b className="cursor-pointer text-primary" onClick={resendCode} />
            ),
          }}
        />
      </p>
    </form>
  );
};

export default PhoneVerifyForm;
