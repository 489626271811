import { ColumnDef, PaginationState } from '@tanstack/react-table';
import React, { memo, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { OrderFilter, Order } from 'common/models';
import {
  Table,
  enumCell,
  dateCell,
  valueCell,
  numberCell,
  CircularIndicator,
} from 'core/components/uikit';

import { useFetchOrdersWithTimer } from './useFetchOrders';
import DisclosureWithTitle from '../DisclosureWithTitle';

export type OrdersTableProps = {
  filters?: OrderFilter;
  title?: string;
};

export const OrdersTable = memo(({ filters = {}, title }: OrdersTableProps) => {
  const { t } = useTranslation();
  const { data, isLoading, fetch, args } = useFetchOrdersWithTimer(filters);
  const columns = useMemo<ColumnDef<Order>[]>(
    () => [
      {
        header: t('components.ordersTable.headers.tiker'),
        accessorKey: 'ticker',
        cell: valueCell(),
      },
      {
        header: t('components.ordersTable.headers.status'),
        accessorKey: 'status',
        cell: enumCell(),
      },
      {
        header: t('components.ordersTable.headers.type'),
        accessorKey: 'type',
        cell: enumCell(),
      },
      {
        header: t('components.ordersTable.headers.priceType'),
        accessorKey: 'priceType',
        cell: enumCell(),
      },
      {
        header: t('components.ordersTable.headers.entryPrice'),
        accessorKey: 'entryPrice',
        cell: numberCell({ prefix: '$' }),
      },
      {
        header: t('components.ordersTable.headers.executedPrice'),
        accessorKey: 'executedPrice',
        cell: numberCell({ prefix: '$' }),
      },
      {
        header: t('components.ordersTable.headers.quantity'),
        accessorKey: 'quantity',
        cell: numberCell(),
      },
      {
        header: t('components.ordersTable.headers.sentDate'),
        accessorKey: 'sentDate',
        cell: dateCell(),
      },
      {
        header: t('components.ordersTable.headers.closeTriggerType'),
        accessorKey: 'closeTriggerType',
        cell: enumCell(),
      },
    ],
    [t]
  );

  const onPaginationChanged = useCallback(
    async ({ pageIndex, pageSize }: PaginationState) => {
      const newArgs = {
        ...(args ?? {}),
        page: pageIndex + 1,
        perPage: pageSize,
      };
      await fetch(newArgs);
    },
    [args]
  );

  return (
    <DisclosureWithTitle
      defaultOpen={true}
      title={
        <div className="flex flex-row items-center gap-2">
          <h4>{title ?? t('components.ordersTable.title')}</h4>
          {isLoading && <CircularIndicator size={24} />}
        </div>
      }
    >
      <Table
        data={data?.items ?? []}
        columns={columns}
        totalCount={data?.filtered ?? 0}
        onPaginationChanged={onPaginationChanged}
        options={{
          initialState: {
            pagination: {
              pageIndex: (args?.page ?? 1) - 1,
              pageSize: args?.perPage,
            },
          },
        }}
      />
    </DisclosureWithTitle>
  );
});

OrdersTable.displayName = 'OrdersTable';

export default OrdersTable;
