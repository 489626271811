import {
  Checkbox as EpCheckbox,
  Field,
  Label,
  CheckboxProps as EpCheckboxProps,
} from '@headlessui/react';
import { CheckIcon } from '@heroicons/react/16/solid';
import { clsx } from 'clsx';
import React, { FC } from 'react';

import { InputProps } from './types';

export type CheckboxProps = Omit<InputProps, 'error'> & EpCheckboxProps;

const Checkbox: FC<CheckboxProps> = ({
  label,
  className,
  disabled,
  ...checkboxProps
}) => {
  return (
    <Field
      className={clsx('flex flex-row items-center gap-2', className)}
      disabled={disabled}
    >
      <EpCheckbox
        {...checkboxProps}
        disabled={disabled}
        className="group flex size-6 items-center justify-center rounded border border-slate-400 bg-slate-100 data-[checked]:bg-primary data-[disabled]:bg-slate-200"
      >
        <CheckIcon className="hidden size-4 fill-white group-data-[checked]:block" />
      </EpCheckbox>
      {label && <Label className="caption text-xs">{label}</Label>}
    </Field>
  );
};

export default Checkbox;
