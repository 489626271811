import * as yup from 'yup';

import { createOtpCodeSchema } from 'core/components/form';

export const phoneVerifySchema = yup
  .object({
    code: createOtpCodeSchema(),
  })
  .required();

export type PhoneVerifyFormData = yup.InferType<typeof phoneVerifySchema>;
