import React from 'react';
import { useTranslation } from 'react-i18next';

const Splash = () => {
  const { t } = useTranslation();

  return (
    <div className="flex min-h-screen items-center justify-center">
      <div className="animate-zoom">
        <h4>{t('components.appLogo')}</h4>
      </div>
    </div>
  );
};

export default Splash;
