import React from 'react';
import { useParams } from 'react-router-dom';

import { Wrapper } from 'core/components/common';
import { LastActionsTable, PositionTable } from 'features/components';

import { SessionParams, SessionTickers } from './components';

const SessionDetails = () => {
  const { sessionId } = useParams();

  return (
    <Wrapper internalClassName="flex flex-col gap-8">
      <SessionParams sessionId={sessionId ?? '0'} />
      <LastActionsTable filters={{ sessionId }} />
      <SessionTickers sessionId={sessionId ?? '0'} />
      <PositionTable filters={{ sessionId }} />
    </Wrapper>
  );
};

export default SessionDetails;
