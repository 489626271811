import { ColumnDef, PaginationState } from '@tanstack/react-table';
import React, { FC, Fragment, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Action, ActionFilter, ActionType } from 'common/models';
import { Option } from 'common/types';
import {
  CircularIndicator,
  dateCell,
  enumCell,
  FiltersProps,
  Select,
  Table,
  valueCell,
} from 'core/components/uikit';

import { useFetchActionsWithTimer } from './useFetchActions';
import DisclosureWithTitle from '../DisclosureWithTitle';

type ActionFilterOption = Option<ActionType | null>;

export type LastActionsProps = {
  filters?: ActionFilter;
  title?: string;
};

export type ActionsTableFiltersProps = FiltersProps<Action> &
  Pick<ReturnType<typeof useFetchActionsWithTimer>, 'args' | 'fetch'>;

const ActionsTableFilters: FC<ActionsTableFiltersProps> = ({
  setPagination,
  fetch,
  args,
}) => {
  const { t } = useTranslation();

  const filterOptions = useMemo<ActionFilterOption[]>(
    () => [
      {
        value: null,
        label: t('components.lastActionsTable.filters.all'),
      },
      {
        value: 'STATUS',
        label: t('components.lastActionsTable.filters.status'),
      },
      {
        value: 'ALERT',
        label: t('components.lastActionsTable.filters.alerts'),
      },
      {
        value: 'TRIGGER',
        label: t('components.lastActionsTable.filters.trigger'),
      },
      {
        value: 'TRADE',
        label: t('components.lastActionsTable.filters.trades'),
      },
      {
        value: 'ERROR',
        label: t('components.lastActionsTable.filters.errors'),
      },
    ],
    [t]
  );

  const onChangeFiltration = useCallback(
    (newFilters: ActionFilter) => {
      fetch({
        ...args,
        ...newFilters,
        page: 1,
      });
      setPagination((old) => ({ ...old, pageIndex: 0 }));
    },
    [args, fetch, setPagination]
  );

  const value =
    filterOptions.find((item) => item.value === args?.type) ?? filterOptions[0];

  return (
    <Fragment>
      <Select
        value={value}
        className="max-w-52"
        onChange={(newValue) => {
          onChangeFiltration({
            type: (newValue as ActionFilterOption).value ?? undefined,
          });
        }}
        options={filterOptions}
      />
    </Fragment>
  );
};

const LastActions: FC<LastActionsProps> = ({ filters = {}, title }) => {
  const { t } = useTranslation();
  const { data, fetch, isLoading, args, startTimer, stopTimer } =
    useFetchActionsWithTimer(filters);

  const columns = useMemo<ColumnDef<Action>[]>(
    () => [
      {
        header: t('components.lastActionsTable.headers.type'),
        accessorKey: 'type',
        cell: enumCell(),
      },
      {
        header: t('components.lastActionsTable.headers.text'),
        accessorKey: 'text',
        cell: valueCell(),
      },
      {
        header: t('components.lastActionsTable.headers.date'),
        accessorKey: 'date',
        cell: dateCell(),
      },
    ],
    [t]
  );

  const onPaginationChanged = useCallback(
    async ({ pageIndex, pageSize }: PaginationState) => {
      await fetch({
        ...(args ?? {}),
        page: pageIndex + 1,
        perPage: pageSize,
      });
    },
    [fetch, args]
  );

  const renderFilters = useCallback(
    (props: FiltersProps<Action>) => (
      <ActionsTableFilters {...props} fetch={fetch} args={args} />
    ),
    [fetch, args]
  );

  const toogleTimer = useCallback(
    (open: boolean) => {
      if (open) {
        stopTimer();
      } else {
        startTimer(5000);
      }
    },
    [stopTimer, startTimer]
  );

  return (
    <DisclosureWithTitle
      title={
        <div className="flex flex-row items-center gap-2">
          <h4>{title ?? t('pages.sessionDetails.lastActions')}</h4>
          {isLoading && <CircularIndicator size={24} />}
        </div>
      }
      onClick={toogleTimer}
    >
      <Table
        data={data?.items ?? []}
        columns={columns}
        totalCount={data?.filtered ?? 0}
        onPaginationChanged={onPaginationChanged}
        options={{
          initialState: {
            pagination: {
              pageIndex: (args?.page ?? 1) - 1,
              pageSize: args?.perPage ?? 10,
            },
          },
        }}
        filters={renderFilters}
      />
    </DisclosureWithTitle>
  );
};

export default LastActions;
