import { yupResolver } from '@hookform/resolvers/yup';
import { t } from 'i18next';
import React, { FC, ReactElement } from 'react';
import { DefaultValues, FormProvider, useForm } from 'react-hook-form';

import {
  clearNullFieldsFromObject,
  transformEmptyObjectsToNull,
} from 'common/utils';
import { TextInput } from 'core/components/form';
import { CircularIndicator } from 'core/components/uikit';

import {
  HoursParameters,
  HoursParametersProps,
  CloseAndFilterParameters,
  CloseAndFilterParametersProps,
} from './components';
import {
  createEditSessionSchema,
  CreateEditSessionFormData,
} from './validationSchema';

export type CreateEditSessionFormProps = {
  disabled?: CloseAndFilterParametersProps['disabled'] &
    HoursParametersProps['disabled'] & {
      submitButton?: boolean;
      sessionName?: boolean;
    };
  actionButtonText?: string;
  renderActionButton?: () => ReactElement;
  defaultValues?: DefaultValues<CreateEditSessionFormData>;
  onSubmit: (values: CreateEditSessionFormData) => Promise<void>;
};

const CreateEditSessionForm: FC<CreateEditSessionFormProps> = ({
  onSubmit,
  disabled,
  defaultValues,
  actionButtonText,
  renderActionButton,
}) => {
  const methods = useForm<CreateEditSessionFormData>({
    resolver: yupResolver(createEditSessionSchema),
    defaultValues,
    mode: 'all',
  });

  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onHandleSubmit = handleSubmit(async (values) => {
    const transformedValues =
      transformEmptyObjectsToNull(values) ?? ({} as CreateEditSessionFormData);
    const cleanedValues = clearNullFieldsFromObject(transformedValues);
    await onSubmit(cleanedValues);
  });

  return (
    <FormProvider {...methods}>
      <form onSubmit={onHandleSubmit} className="flex flex-col gap-6">
        <TextInput
          control={control}
          name="sessionName"
          componentProps={{
            disabled: disabled?.sessionName,
            type: 'text',
            label: t('pages.createSession.sessionName.label'),
          }}
        />
        <HoursParameters control={control} disabled={disabled} />
        <hr className="border-t border-gray-300" />
        <CloseAndFilterParameters control={control} disabled={disabled} />
        {renderActionButton ? (
          renderActionButton()
        ) : (
          <button type="submit" disabled={disabled?.submitButton}>
            {isSubmitting ? (
              <CircularIndicator />
            ) : (
              (actionButtonText ?? t('common.continue'))
            )}
          </button>
        )}
      </form>
    </FormProvider>
  );
};

export default CreateEditSessionForm;
