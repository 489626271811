import { useFetch } from 'common/hooks';
import { sessionsRepository } from 'common/repositories';

export type SessionDetailsFetchArgs = Parameters<
  typeof sessionsRepository.getSessionById
>[0];

export const useFetchSessionDetails = (args: SessionDetailsFetchArgs) => {
  const { data, ...hook } = useFetch(sessionsRepository.getSessionById, {
    instantFetch: true,
    args,
  });

  return { data: data?.data, ...hook };
};
