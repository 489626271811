import React, { FC } from 'react';
import { UseControllerProps } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { SelectInput } from 'core/components/form';

import { CreateEditSessionFormData } from '../validationSchema';

const stocks = [
  { value: 'AAPL', label: 'Apple Inc.' },
  { value: 'MSFT', label: 'Microsoft Corporation' },
  { value: 'GOOGL', label: 'Alphabet Inc.' },
  { value: 'AMZN', label: 'Amazon.com, Inc.' },
  { value: 'TSLA', label: 'Tesla, Inc.' },
  { value: 'META', label: 'Meta Platforms, Inc.' },
  { value: 'NVDA', label: 'NVIDIA Corporation' },
  { value: 'JPM', label: 'JPMorgan Chase & Co.' },
  { value: 'V', label: 'Visa Inc.' },
  { value: 'UNH', label: 'UnitedHealth Group Incorporated' },
];

export type BlacklistProps = Pick<
  UseControllerProps<CreateEditSessionFormData>,
  'control'
> & {
  disabled?: boolean;
};

const Blacklist: FC<BlacklistProps> = ({ control, disabled }) => {
  const { t } = useTranslation();

  return (
    <div className="flex flex-1 flex-col gap-2">
      <h4>{t('pages.createSession.blacklist.label')}</h4>
      <SelectInput
        control={control}
        name="blacklist"
        componentProps={{
          disabled,
          label: t('pages.createSession.blacklist.label'),
          isMulti: true,
          options: stocks,
        }}
      />
    </div>
  );
};

export default Blacklist;
