import { useFetch } from 'common/hooks';
import { positionsRepository } from 'common/repositories';

export type PositionsFetchArgs = Parameters<
  typeof positionsRepository.getPositions
>[0];

export const useFetchPositions = (args: PositionsFetchArgs) => {
  const { data, ...hook } = useFetch(positionsRepository.getPositions, {
    instantFetch: true,
    args,
  });

  return { data: data?.data, ...hook };
};
