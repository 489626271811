import { t } from 'i18next';
import React from 'react';
import { useController, FieldValues } from 'react-hook-form';
import OtpInput, { OTPInputProps } from 'react-otp-input';
import * as yup from 'yup';

import { DefaultFormInputProps } from './types';
import { DefaultInput } from '../uikit';

export const createOtpCodeSchema = (
  { size = 6 }: { size: number } = { size: 6 }
) =>
  yup
    .string()
    .required(
      t('errors.validation.isRequired', {
        name: t('pages.verifyPhone.fields.otpCode'),
      })
    )
    .min(size)
    .max(size);

const OtpCodeInput = <FormValues extends FieldValues>({
  componentProps,
  ...input
}: DefaultFormInputProps<FormValues, OTPInputProps>) => {
  const { field } = useController(input);

  return (
    <OtpInput
      numInputs={6}
      placeholder="000000"
      containerStyle="mb-4 flex justify-between gap-2"
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      renderInput={({ style, ...otherProps }, index) => (
        <DefaultInput
          {...otherProps}
          autoFocus={index === 0}
          className="max-w-14 flex-1 text-center caret-transparent"
        />
      )}
      inputType="number"
      {...componentProps}
      value={field.value}
      onChange={field.onChange}
    />
  );
};

export default OtpCodeInput;
