import { Dialog, DialogPanel } from '@headlessui/react';
import React, { useState } from 'react';

import {
  BurgerMenuButton,
  HeaderButtons,
  HeaderLinks,
} from './HeaderComponents';
import AppLogo from '../../AppLogo';
import Wrapper from '../../Wrapper';

const Header = () => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  return (
    <Wrapper
      as="header"
      className="border-b-[1px] border-gray-300 !py-6 *:flex *:items-center *:justify-between"
    >
      <AppLogo />
      <div className="flex lg:hidden">
        <BurgerMenuButton
          opened={true}
          onClick={() => setMobileMenuOpen(true)}
        />
      </div>
      <div className="hidden items-center lg:flex lg:gap-x-12">
        <HeaderLinks />
      </div>
      <div className="hidden lg:flex lg:items-center lg:gap-x-8">
        <HeaderButtons />
      </div>

      <Dialog
        open={mobileMenuOpen}
        onClose={setMobileMenuOpen}
        className="lg:hidden"
      >
        <DialogPanel className="fixed inset-y-0 right-0 z-10 w-full overflow-y-auto bg-white p-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
          <div className="flex items-center justify-between">
            <AppLogo />
            <BurgerMenuButton
              opened={false}
              onClick={() => setMobileMenuOpen(false)}
            />
          </div>
          <div className="mt-6 flow-root">
            <div className="-my-6 divide-y divide-gray-500/10">
              <div className="flex flex-col space-y-4 py-6">
                <HeaderLinks />
              </div>
              <div className="flex flex-row items-center justify-between py-6">
                <HeaderButtons />
              </div>
            </div>
          </div>
        </DialogPanel>
      </Dialog>
    </Wrapper>
  );
};

export default Header;
