const locale = {
  translation: {
    common: {
      na: 'N/A',
      done: 'Done',
      stop: 'Stop',
      login: 'Login',
      start: 'Start',
      cancel: 'Cancel',
      logout: 'Logout',
      continue: 'Continue',
    },
    noData: {
      general: 'No data',
      actions: 'No actions',
      session: 'No session details',
      tickers: 'No tickers',
      positions: 'No positions',
      orders: 'No orders',
    },
    components: {
      appLogo: 'Trading Bot App',
      footer: {
        copyRights: '© 2024 Trading Bot Inc. | All Rights Reserved',
      },
      header: {
        account: 'Account',
        reports: 'Reports',
        sessions: 'Sessions',
        newSession: 'New Session',
      },
      statuses: {
        error: 'Error',
        active: 'Active',
        paused: 'Paused',
        stopped: 'Stopped',
      },
      confirmActionDialog: {
        title: 'Validation required',
      },
      lastActionsTable: {
        filters: {
          all: 'All',
          status: 'Status',
          alerts: 'Alerts',
          trigger: 'Trigger',
          trades: 'Trades',
          errors: 'Errors',
        },
        headers: {
          type: 'Type',
          text: 'Text',
          date: 'Date',
        },
      },
      table: {
        page: 'Page {{current}} of {{of}}',
        total: 'Total: {{total}}',
        number: 'Number',
      },
      positionsTable: {
        title: 'Positions',
        headers: {
          type: 'Type',
          tiker: 'Ticker',
          status: 'Status',
          quantity: 'Quantity',
          openDate: 'Open date',
          avgPrice: 'AVG price',
          closeDate: 'Close date',
          marketType: 'Market type',
          currentSize: 'Position size',
          currentPrice: 'Crrent price',
          stopLossPrice: 'Stop loss price',
          takeProfitPrice: 'Take profit price',
        },
      },
      ordersTable: {
        title: 'Orders',
        headers: {
          type: 'Type',
          tiker: 'Ticker',
          status: 'Status',
          quantity: 'Quantity',
          sentDate: 'Sent date',
          priceType: 'Price type',
          entryPrice: 'Entry price',
          executedPrice: 'Executed price',
          closeTriggerType: 'Close trigger type',
        },
      },
      tickersTable: {
        title: 'Tickers',
        titles: {
          rth: 'RTH',
          prehours: 'Prehours',
          afterhours: 'Afterhours',
        },
        headers: {
          ticker: 'Ticker',
          price: 'Price',
          marketCap: 'Market Cap',
          listType: 'List Type',
          prevCloseTrigger: 'Prev Close Trigger',
          lastPeriodTrigger: 'Last Period Trigger',
          currentDayVolume: 'Current Day Volume ($)',
          lastPeriodVolume: 'Last Period Volume ($)',
          lastPeriodMinutes: 'Last Period (Minutes)',
          priceChangePercent: 'Price Change (%)',
          updatedAt: 'Updated At',
        },
      },
    },
    pages: {
      sessions: {
        title: 'Strategy Sessions',
        subtitle: 'Here you can view all your current trading sessions.',
        createSession: 'Create Session',
        editSession: 'Edit Session',
        sessionDetails: 'Session Details',
      },
      sessionDetails: {
        lastActions: 'Last actions',
        sessionParams: 'Session parameters',
      },
      createSession: {
        title: 'Create session',
        action: 'Create session',
        and: 'AND',
        sessionName: {
          label: 'Session name',
        },
        rth: {
          title: 'RTH',
          form: {
            marketHoursRange: {
              label: 'Market Hours (9:30 AM – 4:00 PM)',
            },
          },
        },
        prehours: {
          title: 'Prehours',
          form: {
            marketHoursRange: {
              label: 'Premarket (4:00 AM – 9:30 AM)',
            },
          },
        },
        afterhours: {
          title: 'Afterhours',
          form: {
            marketHoursRange: {
              label: 'Afterhours (4:00 PM – 8:00 PM)',
            },
          },
        },
        common: {
          form: {
            filterParams: {
              title: 'Filters',
              exchanges: {
                label: 'Exchanges',
                nasdaq: 'NASDAQ',
                nyse: 'NYSE',
              },
              priceChangePercentFromPrevCloseRange: {
                min: '% Change from previous close (min)',
                max: '% Change from previous close (max)',
              },
              marketCapRange: {
                min: 'Market cap (millions, min)',
                max: 'Market cap (millions, max)',
              },
              priceRange: {
                min: 'Price ($, min)',
                max: 'Price ($, max)',
              },
              lastPeriodInMinutes: {
                label: 'In last min',
              },
              currentDayVolumeIn$Range: {
                min: '$ Current day volume (millions, min)',
                max: '$ Current day volume (millions, max)',
              },
              lastPeriodVolumeIn$Range: {
                min: '$ Last period volume (millions, min)',
                max: '$ Last period volume (millions, max)',
              },
            },
            triggerParams: {
              title: 'Triggers',
              error:
                'You must fill either both "{{field1}}" and "{{field2}}", or "{{field3}}"',
              lastPeriodInMinutes: {
                name: 'Last period',
                label: 'Last period in minutes',
              },
              lastPeriodPriceChangePercent: {
                name: '% Last period',
                label: '% Last period price change',
              },
              priceChangePercentFromPrevClose: {
                name: 'Price change',
                label: '% Price change from previous close',
              },
            },
            openPositionParams: {
              title: 'Open position params',
              positionSizeIn$: {
                name: 'Position size',
                label: 'Position size ($)',
              },
              entriesCount: {
                name: 'Number of entries',
                label: 'Number of entries',
              },
              entryIntervalInSec: {
                name: 'Entry interval',
                label: 'Entry interval (sec)',
              },
              orderType: {
                name: 'Order Type',
                label: 'Order Type',
                marketOrder: 'Use mkt orders',
                askLimit: 'Use ask-.01 limit orders',
                bidLimit: 'Use bid limit orders',
              },
              limitOrderRefreshIntervalInSec: {
                name: 'Refresh interval',
                label: 'Limit order refresh interval (sec)',
              },
              chaseDown: {
                name: 'Chase down',
                label: 'Chase down %',
              },
              additionalPositions: {
                add: 'Add additional position',
                positionSize: {
                  name: 'Position size',
                  label: 'Position size {{number}}',
                },
                increaseFromInitialBuy: {
                  name: 'Increase from initial buy',
                  label: 'Increase from initial buy % {{number}}',
                },
              },
            },
          },
        },
        closePositionParams: {
          title: 'Closing parameters (shared by all trades)',
          form: {
            stopLossSteps: {
              positionSize: {
                name: 'Position size',
                label: 'Stop loss – max position size {{number}} ($)',
              },
              priceChangePercent: {
                name: '% close',
                label: '% close',
              },
            },
            takeProfitSteps: {
              priceChangePercent: {
                name: '% close',
                label: 'Take profit % close {{number}}',
              },
            },
          },
        },
        timeBasedPositionParams: {
          title: 'Time Based Position Close',
          form: {
            closeSteps: {
              date: {
                name: 'Time',
                label: 'Time {{number}} to close EST',
              },
              positionSizePercent: {
                name: 'Time % close',
                label: 'Time {{number}} % close',
              },
            },
            closeAllPositionsDate: 'Time close ALL EST',
            orderType: {
              label: 'Order type for timed stops',
              marketOrder: 'Use mkt orders for timed stops',
              askLimit: 'Use ask limits for timed stops',
              bidLimit: 'Use bid+.01 limits for timed stops',
            },
            limitOrderRefreshIntervalInSec:
              'Limit order refresh interval (sec)',
          },
        },
        blacklist: {
          label: 'Blacklist',
          name: 'Blacklist',
          placeholder: 'Add stock to blacklist',
        },
      },
      editSession: {
        action: 'Apply changes',
        openPositions: {
          title: 'Open positions',
          entryText:
            '{{type}}, {{ticker}} ${{total}}, price ${{entryPrice}}, shares to cover {{quantity}}, sold price ${{currentPrice}}, potential Profit or Loss: ${{profitLoss}}.',
        },
        statuses: {
          title: 'Statuses:',
        },
        confrimDialog: {
          title: 'Are you sure you want to {{action}} the bot?',
          subtitle: 'You have open positions that need to be closed manually.',
        },
      },
      account: {
        title: 'Account Information',
      },
      reports: {
        title: 'Reports',
      },
      phone: {
        fields: {
          phone: 'Phone',
          password: 'Password',
        },
      },
      verifyPhone: {
        fields: {
          otpCode: 'OTP Code',
        },
        action: 'Send',
        helperText:
          'Please enter the OTP code sent to your phone. The code is valid for 2 minutes. If you didn’t receive the code, <button>click here</button> to resend it again',
      },
    },
    notify: {
      resendCode: 'Confirmation code was sent successfully',
    },
    errors: {
      api: {
        notAuthorized: 'Not authorized',
        other: 'Something went wrong',
        notFound: 'Not found',
      },
      validation: {
        isRequired: '{{name}} is required',
        isInvalid: '{{name}} is invalid',
        minLength: '{{name}} should be at least {{length}}',
        maxLength: '{{name}} should be at most {{length}}',
        cantBeGreater: '{{name}} cannot be greater than {{length}}',
        cantBeLess: '{{name}} cannot be less than {{length}}',
        mustBe: '{{name}} must be a {{type}}',
        range: {
          mustIncludeBoth:
            '{{name}} must include both minimum and maximum values.',
        },
        password: {
          uppercase: '{{name}} must contain at least one uppercase letter',
          lowercase: '{{name}} must contain at least one lowercase letter',
          number: '{{name}} must contain at least one number',
          specialCharacter:
            '{{name}} must contain at least one special character (@, $, !, %, *, ?, &, _, -)',
        },
        time: 'Please enter a valid time (e.g., 2:00 PM)',
      },
    },
  },
};

export default locale;
