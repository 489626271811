import React from 'react';
import { useController, FieldValues } from 'react-hook-form';

import { DefaultFormInputProps } from './types';
import { Switch, SwitchProps } from '../uikit';

const SwitchInput = <FormValues extends FieldValues>({
  componentProps,
  ...input
}: DefaultFormInputProps<FormValues, SwitchProps>) => {
  const { field } = useController(input);
  const { value, onChange } = field;

  return <Switch {...componentProps} checked={value} onChange={onChange} />;
};

export default SwitchInput;
