import { useEffect } from 'react';
import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';

import {
  Session,
  PaginatedResult,
  DeprecatedSession,
  DeprecatedCreateSessionBody,
} from 'common/models';
import { sessionsRepository } from 'common/repositories';
import { sessionsToDeprecatedSessions } from 'common/repositories/mockData';

type State = {
  isLoading: boolean;
  sessions: DeprecatedSession[] | null;
};

type Actions = {
  fetch: () => Promise<PaginatedResult<Session> | null>;
  setLoading: (value: boolean) => void;
  setSession: (session: DeprecatedSession) => void;
  setSessions: (sessions: DeprecatedSession[]) => void;
  createSession: (
    body: DeprecatedCreateSessionBody
  ) => Promise<DeprecatedSession>;
};

export type SessionsStore = State & Actions;

const defaultState: State = {
  isLoading: false,
  sessions: null,
};

const useSessionsStore = create<SessionsStore>()(
  immer((set, get) => ({
    ...defaultState,

    setLoading: (value) =>
      set((state) => {
        state.isLoading = value;
      }),

    setSessions: (value) =>
      set((state) => {
        state.sessions = value;
      }),

    fetch: async () => {
      const { setLoading, setSessions } = get();

      setLoading(true);

      const res = await sessionsRepository.getSessions();

      setLoading(false);

      const data = res.data;

      if (data === null) {
        return null;
      }

      const deprecatedSessions = sessionsToDeprecatedSessions(
        data?.items ?? []
      );

      setSessions(deprecatedSessions);

      return data;
    },

    setSession: async (session) => {
      const { sessions } = get();

      if (!sessions) {
        return;
      }

      const sessionIndex = sessions.findIndex(
        (item) => item?.sessionId === session.sessionId
      );

      if (sessionIndex === -1) {
        return;
      }

      set((draft) => {
        if (draft.sessions) {
          draft.sessions[sessionIndex] = session;
        }
      });

      return true;
    },

    createSession: async (body) => {
      const { sessions, setSessions } = get();

      await new Promise((resolve) => setTimeout(resolve, 1000));

      const newSessionId = ((sessions?.length ?? 0) + 1).toString();
      const newSession: DeprecatedSession = {
        sessionId: newSessionId,
        sessionName: body.sessionName ?? `Session ${newSessionId}`,
        rthParams: body.rthParams,
        phParams: body.phParams,
        ahParams: body.ahParams,
        closePositionParams: body.closePositionParams ?? null,
        blacklist: body.blacklist ?? [],
        status: 'stopped',
        marketStatus: 'closed',
        searchStatus: 'stopped',
        monitorStatus: 'stopped',
        lastActions: [],
        watchlist: null,
        positions: [],
      };
      const newSessions = [...(sessions ?? []), newSession];

      setSessions(newSessions);

      return newSession;
    },
  }))
);

export const useFetchSessions = () => {
  const data = useSessionsStore((state) => state.sessions);
  const isLoading = useSessionsStore((state) => state.isLoading);

  useEffect(() => {
    if (data === null) {
      useSessionsStore.getState().fetch();
    }
  }, []);

  return { data, isLoading };
};

export default useSessionsStore;
