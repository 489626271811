import {
  Radio as EpRadio,
  Field,
  Label,
  RadioProps as EpRadioProps,
} from '@headlessui/react';
import { clsx } from 'clsx';
import React, { FC } from 'react';

import { InputProps } from './types';

export type RadioProps = Omit<InputProps, 'error'> & EpRadioProps;

const Radio: FC<RadioProps> = ({
  label,
  className,
  disabled,
  ...radioProps
}) => {
  return (
    <Field
      className={clsx('flex flex-row items-center gap-2', className)}
      disabled={disabled}
    >
      <EpRadio
        {...radioProps}
        disabled={disabled}
        className={clsx(
          'group flex size-6 items-center justify-center rounded-full border border-slate-400 bg-slate-100',
          disabled
            ? 'bg-slate-200 data-[checked]:bg-primary/50'
            : 'data-[checked]:bg-primary'
        )}
      >
        <span className="invisible size-3 rounded-full bg-white group-data-[checked]:visible" />
      </EpRadio>
      {label && <Label className="caption text-xs">{label}</Label>}
    </Field>
  );
};

export default Radio;
