import React, { FC } from 'react';
import { UseControllerProps } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
  formatedTimeToMinutesFromStartOfDay,
  minutesFromStartOfDayToFormatedTime,
} from 'common/utils';
import { RangeInput, SwitchInput } from 'core/components/form';

export type CommonInputsProps = Pick<
  UseControllerProps<CreateEditSessionFormData>,
  'control'
> & {
  disabled?: boolean;
};

import CommonInputs from './CommonInputs';
import { CreateEditSessionFormData } from '../validationSchema';

const AfterhoursParameters: FC<CommonInputsProps> = ({ control, disabled }) => {
  const { t } = useTranslation();

  return (
    <div className="flex flex-1 flex-col gap-3">
      <div className="flex items-center justify-between gap-3">
        <h4>{t('pages.createSession.afterhours.title')}</h4>
        <SwitchInput
          control={control}
          name="ahParams.active"
          componentProps={{ disabled }}
        />
      </div>
      <RangeInput
        componentProps={{
          label: t(
            'pages.createSession.afterhours.form.marketHoursRange.label'
          ),
          min: '4:00 PM',
          max: '8:00 PM',
          step: 30,
          disabled,
          renderLabel: minutesFromStartOfDayToFormatedTime,
          parseValue: formatedTimeToMinutesFromStartOfDay,
          formatValue: minutesFromStartOfDayToFormatedTime,
        }}
        control={control}
        name="ahParams.marketHoursRange"
      />
      <CommonInputs control={control} disabled={disabled} basename="ahParams" />
    </div>
  );
};

export default AfterhoursParameters;
