import { useEffect } from 'react';
import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';

import { DeprecatedCreateSessionBody, DeprecatedSession } from 'common/models';
import { sessionsRepository } from 'common/repositories';
import { useSessionsStore } from 'common/stores';

type State = {
  isLoading: boolean;
  session: DeprecatedSession | null;
};

type Actions = {
  fetch: (
    params: Parameters<typeof sessionsRepository.getSessionByIdMocked>[0]
  ) => Promise<DeprecatedSession | null>;
  stopSession: () => Promise<void>;
  startSession: () => Promise<void>;
  setLoading: (value: boolean) => void;
  setSession: (sessions: DeprecatedSession) => void;
  editSession: (body: DeprecatedCreateSessionBody) => Promise<boolean>;
};

export type SessionStore = State & Actions;

const defaultState: State = {
  isLoading: false,
  session: null,
};

const useSessionDetailsStore = create<SessionStore>()(
  immer((set, get) => ({
    ...defaultState,

    setLoading: (value) =>
      set((state) => {
        state.isLoading = value;
      }),

    setSession: (value) =>
      set((state) => {
        state.session = value;
      }),

    fetch: async ({ sessionId }) => {
      const { setLoading, setSession } = get();

      setLoading(true);

      // TODO: remove when backend will be ready
      let data: DeprecatedSession | null | undefined;
      const sessions = useSessionsStore.getState().sessions ?? [];
      data = sessions.find((item) => item.sessionId === sessionId);

      if (!data) {
        const res = await sessionsRepository.getSessionByIdMocked({
          sessionId,
        });
        data = res.data;
      } else {
        await new Promise((resolve) => setTimeout(resolve, 500));
      }

      setLoading(false);

      // const data = res.data;

      if (data === null) {
        return null;
      }

      setSession(data);

      return data;
    },

    editSession: async (body) => {
      const { session, setSession } = get();
      const { setSession: sessionsStoreSetSesssion } =
        useSessionsStore.getState();

      if (!session) {
        return false;
      }

      await new Promise((resolve) => setTimeout(resolve, 500));

      const newSession = {
        ...session,
        ...body,
      };

      setSession(newSession);
      sessionsStoreSetSesssion(newSession);

      return true;
    },

    stopSession: async () => {
      const { session, setSession } = get();
      const { setSession: sessionsStoreSetSesssion } =
        useSessionsStore.getState();

      if (!session) {
        return;
      }

      await new Promise((resolve) => setTimeout(resolve, 500));

      const newSession: DeprecatedSession = {
        ...session,
        status: 'stopped',
      };

      setSession(newSession);
      sessionsStoreSetSesssion(newSession);
    },

    startSession: async () => {
      const { session, setSession } = get();
      const { setSession: sessionsStoreSetSesssion } =
        useSessionsStore.getState();

      if (!session) {
        return;
      }

      await new Promise((resolve) => setTimeout(resolve, 500));

      const newSession: DeprecatedSession = {
        ...session,
        status: 'active',
      };

      setSession(newSession);
      sessionsStoreSetSesssion(newSession);
    },
  }))
);

export const useFetchSession = (sessionId: string) => {
  const data = useSessionDetailsStore((state) => state.session);
  const isLoading = useSessionDetailsStore((state) => state.isLoading);

  useEffect(() => {
    if (data?.sessionId !== sessionId) {
      useSessionDetailsStore.getState().fetch({ sessionId });
    }
  }, []);

  return { data, isLoading };
};

export default useSessionDetailsStore;
