import { queryString } from 'common/utils';

export type PhoneQueryParams = { phone: string };
export type ErrorPageQueryParams = {
  status?: number | null;
  message?: string | null;
};

class Paths {
  account = '/account';
  reports = '/reports';
  sessions = '/sessions';

  createSessionKey = 'create';
  createSession = `${this.sessions}/${this.createSessionKey}`;

  sessionKey = ':sessionId';
  getSessionDetails = (id: string): string => `${this.sessions}/${id}`;
  sessionEditKey = 'edit';
  getEditSession = (id: string): string =>
    `${this.getSessionDetails(id)}/${this.sessionEditKey}`;

  auth = '/auth';

  phoneKey = 'phone';
  phone = `${this.auth}/${this.phoneKey}`;

  phoneVerifyKey = 'verify-phone';
  getPhoneVerify = (params: PhoneQueryParams): string =>
    queryString.stringifyUrl({
      url: `${this.auth}/${this.phoneVerifyKey}`,
      query: params,
    });

  error = '/error';
  getError = (params: ErrorPageQueryParams): string =>
    `${this.error}?${queryString.stringify(params)}`;
}

const PATHS = new Paths();

export default PATHS;
