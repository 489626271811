import { t } from 'i18next';
import * as yup from 'yup';

import {
  createTimeInputSchema,
  createMinMaxInputSchema,
  createNumberInputSchema,
  createRangeInputSchema,
  createSelectInputSchema,
  createRadioGroupInputSchema,
  createCheckboxGroupInputSchema,
} from 'core/components/form';

const triggerParamsSchema = yup.object({
  lastPeriodInMinutes: createNumberInputSchema({
    required: true,
    min: 0,
    max: 1000000,
    name: t(
      'pages.createSession.common.form.triggerParams.lastPeriodInMinutes.name'
    ),
  }),
  lastPeriodPriceChangePercent: createNumberInputSchema({
    required: true,
    min: 0,
    max: 1000000,
    name: t(
      'pages.createSession.common.form.triggerParams.lastPeriodPriceChangePercent.name'
    ),
  }),
  priceChangePercentFromPrevClose: createNumberInputSchema({
    required: true,
    min: 0,
    max: 1000000,
    name: t(
      'pages.createSession.common.form.triggerParams.priceChangePercentFromPrevClose.name'
    ),
  }),
});

const filterParamsSchema = yup.object({
  exchanges: createCheckboxGroupInputSchema({
    name: t('pages.createSession.common.form.filterParams.exchanges.label'),
    required: true,
  }),
  priceChangePercentFromPrevCloseRange: createMinMaxInputSchema({
    min: 0,
    max: 1000,
    required: false,
  }),
  marketCapRange: createMinMaxInputSchema({
    min: 0,
    max: 1000000,
    required: false,
  }),
  priceRange: createMinMaxInputSchema({
    min: 0,
    max: 10000,
    required: false,
  }),
  lastPeriodInMinutes: createNumberInputSchema({
    required: false,
    min: 0,
    max: 1000000,
    name: 'lastPeriodInMinutes',
  }),
  currentDayVolumeIn$Range: createMinMaxInputSchema({
    min: 0,
    max: 10000,
    required: false,
  }),
  lastPeriodVolumeIn$Range: createMinMaxInputSchema({
    min: 0,
    max: 10000,
    required: false,
  }),
});

const openPositionParams = yup.object({
  positionSizeIn$: createNumberInputSchema({
    required: true,
    min: 0,
    max: 1000000,
    name: t(
      'pages.createSession.common.form.openPositionParams.positionSizeIn$.name'
    ),
  }),
  entriesCount: createNumberInputSchema({
    required: true,
    min: 0,
    max: 1000000,
    name: t(
      'pages.createSession.common.form.openPositionParams.entriesCount.name'
    ),
  }),
  entryIntervalInSec: createNumberInputSchema({
    required: true,
    min: 0,
    max: 60,
    name: t(
      'pages.createSession.common.form.openPositionParams.entryIntervalInSec.name'
    ),
  }),
  orderType: createRadioGroupInputSchema({
    name: t(
      'pages.createSession.common.form.openPositionParams.orderType.name'
    ),
    required: true,
  }),
  limitOrderRefreshIntervalInSec: createNumberInputSchema({
    required: true,
    min: 0,
    max: 60,
    name: t(
      'pages.createSession.common.form.openPositionParams.limitOrderRefreshIntervalInSec.name'
    ),
  }),
  chaseDown: createNumberInputSchema({
    required: true,
    min: 0,
    max: 1000,
    name: t(
      'pages.createSession.common.form.openPositionParams.chaseDown.name'
    ),
  }),
  additionalPositions: yup.array().of(
    yup.object({
      positionSize: createNumberInputSchema({
        required: true,
        min: 0,
        max: 1000000,
        name: t(
          'pages.createSession.common.form.openPositionParams.additionalPositions.positionSize.name'
        ),
      }),
      increaseFromInitialBuy: createNumberInputSchema({
        required: true,
        min: 0,
        max: 100,
        name: t(
          'pages.createSession.common.form.openPositionParams.additionalPositions.increaseFromInitialBuy.name'
        ),
      }),
    })
  ),
});

const baseHoursSchema = yup.object({
  active: yup.boolean().required(),
  marketHoursRange: createRangeInputSchema({
    name: 'Range',
    required: true,
  }),
  filterParams: filterParamsSchema,
  triggerParams: triggerParamsSchema,
  openPositionParams: openPositionParams,
});

const closePositionParamsSchema = yup.object({
  stopLossSteps: yup.array().of(
    yup.object({
      positionSize: createNumberInputSchema({
        required: true,
        min: 0,
        max: 10000,
        name: t(
          'pages.createSession.closePositionParams.form.stopLossSteps.positionSize.name'
        ),
      }),
      priceChangePercent: createNumberInputSchema({
        required: true,
        min: 0,
        max: 100,
        name: t(
          'pages.createSession.closePositionParams.form.stopLossSteps.priceChangePercent.name'
        ),
      }),
    })
  ),
  takeProfitSteps: yup.array().of(
    yup.object({
      priceChangePercent: createNumberInputSchema({
        required: true,
        min: 0,
        max: 10000,
        name: t(
          'pages.createSession.closePositionParams.form.takeProfitSteps.priceChangePercent.name'
        ),
      }),
    })
  ),
  timeBasedParams: yup.object({
    closeSteps: yup.array().of(
      yup.object({
        date: createTimeInputSchema({
          name: t(
            'pages.createSession.timeBasedPositionParams.form.closeSteps.date.name'
          ),
          required: true,
        }),
        positionSizePercent: createNumberInputSchema({
          required: true,
          min: 0,
          max: 100,
          name: t(
            'pages.createSession.timeBasedPositionParams.form.closeSteps.positionSizePercent.name'
          ),
        }),
      })
    ),
    closeAllPositionsDate: createTimeInputSchema({
      name: t(
        'pages.createSession.timeBasedPositionParams.form.closeAllPositionsDate'
      ),
      required: true,
    }),
    orderType: createRadioGroupInputSchema({
      name: t(
        'pages.createSession.timeBasedPositionParams.form.orderType.label'
      ),
      required: true,
    }),
    limitOrderRefreshIntervalInSec: createNumberInputSchema({
      required: true,
      min: 0,
      max: 60,
      name: t(
        'pages.createSession.timeBasedPositionParams.form.limitOrderRefreshIntervalInSec'
      ),
    }),
  }),
});

const sessionNameSchema = yup
  .string()
  .transform((value, originalValue) => {
    if (typeof originalValue === 'string') {
      return originalValue.trim() === '' ? null : value;
    }
    return value;
  })
  .required(
    t('errors.validation.isRequired', {
      name: t('pages.createSession.sessionName.label'),
    })
  );

export const createEditSessionSchema = yup
  .object({
    sessionName: sessionNameSchema,
    rthParams: baseHoursSchema,
    phParams: baseHoursSchema,
    ahParams: baseHoursSchema,
    closePositionParams: closePositionParamsSchema,
    blacklist: createSelectInputSchema({
      name: t('pages.createSession.blacklist.name'),
      required: false,
    }),
  })
  .required();

export type CreateEditSessionFormData = yup.InferType<
  typeof createEditSessionSchema
>;
