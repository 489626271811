import React, { FC } from 'react';
import { UseControllerProps } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
  formatedTimeToMinutesFromStartOfDay,
  minutesFromStartOfDayToFormatedTime,
} from 'common/utils';
import { RangeInput, SwitchInput } from 'core/components/form';

import CommonInputs from './CommonInputs';
import { CreateEditSessionFormData } from '../validationSchema';

export type PrehoursParametersProps = Pick<
  UseControllerProps<CreateEditSessionFormData>,
  'control'
> & {
  disabled?: boolean;
};

const PrehoursParameters: FC<PrehoursParametersProps> = ({
  control,
  disabled,
}) => {
  const { t } = useTranslation();

  return (
    <div className="flex flex-1 flex-col gap-3">
      <div className="flex items-center justify-between gap-3">
        <h4>{t('pages.createSession.prehours.title')}</h4>
        <SwitchInput
          control={control}
          name="phParams.active"
          componentProps={{ disabled }}
        />
      </div>
      <RangeInput
        componentProps={{
          label: t('pages.createSession.prehours.form.marketHoursRange.label'),
          min: '4:00 AM',
          max: '9:30 AM',
          step: 30,
          disabled,
          renderLabel: minutesFromStartOfDayToFormatedTime,
          parseValue: formatedTimeToMinutesFromStartOfDay,
          formatValue: minutesFromStartOfDayToFormatedTime,
        }}
        control={control}
        name="phParams.marketHoursRange"
      />
      <CommonInputs control={control} disabled={disabled} basename="phParams" />
    </div>
  );
};

export default PrehoursParameters;
