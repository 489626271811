import { ColumnDef } from '@tanstack/react-table';
import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Ticker } from 'common/models';
import {
  Table,
  enumCell,
  dateCell,
  valueCell,
  numberCell,
} from 'core/components/uikit';

export type TickerTableProps = {
  data: Ticker[];
};

const TickerTable: FC<TickerTableProps> = ({ data }) => {
  const { t } = useTranslation();

  const columns = useMemo<ColumnDef<Ticker>[]>(
    () => [
      {
        header: t('components.tickersTable.headers.ticker'),
        accessorKey: 'ticker',
        cell: valueCell(),
      },
      {
        header: t('components.tickersTable.headers.price'),
        accessorKey: 'price.lastPrice',
        cell: numberCell({ prefix: '$' }),
      },
      {
        header: t('components.tickersTable.headers.marketCap'),
        accessorKey: 'marketCap',
        cell: numberCell({ prefix: '$' }),
      },
      {
        header: t('components.tickersTable.headers.listType'),
        accessorKey: 'listType',
        cell: enumCell(),
      },
      {
        header: t('components.tickersTable.headers.prevCloseTrigger'),
        accessorKey: 'prevCloseTrigger',
        cell: numberCell(),
      },
      {
        header: t('components.tickersTable.headers.lastPeriodTrigger'),
        accessorKey: 'lastPeriodTrigger',
        cell: numberCell(),
      },
      {
        header: t('components.tickersTable.headers.currentDayVolume'),
        accessorKey: 'currentDayVolumeIn$',
        cell: numberCell({ prefix: '$' }),
      },
      {
        header: t('components.tickersTable.headers.lastPeriodVolume'),
        accessorKey: 'lastPeriodVolumeIn$',
        cell: numberCell({ prefix: '$' }),
      },
      {
        header: t('components.tickersTable.headers.lastPeriodMinutes'),
        accessorKey: 'lastPeriodInMinutes',
        cell: valueCell(),
      },
      {
        header: t('components.tickersTable.headers.priceChangePercent'),
        accessorKey: 'priceChangePercentFromPrevClose',
        cell: numberCell({ suffix: '%' }),
      },
      {
        header: t('components.tickersTable.headers.updatedAt'),
        accessorKey: 'updatedAt',
        cell: dateCell(),
      },
    ],
    [t]
  );

  return (
    <Table
      data={data}
      columns={columns}
      totalCount={data.length}
      options={{ manualPagination: false }}
    />
  );
};

export default TickerTable;
