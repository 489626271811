import React, { FC, Fragment } from 'react';

import { DeprecatedSession } from 'common/models';
import { Nullable } from 'common/types';

import StatusText, { Statuses } from './StatusText';

export type SessionStatusesProps = Pick<
  DeprecatedSession,
  'status' | 'monitorStatus'
>;

export const useSessionStatuses = ({
  status,
  monitorStatus,
}: SessionStatusesProps): Nullable<{
  botStatus: Statuses;
  rthStatus: Statuses;
  ahStatus: Statuses;
  phStatus: Statuses;
}> => {
  const isRthActive = monitorStatus === 'rthMarket';
  const isAhActive = monitorStatus === 'ahMarket';
  const isPhActive = monitorStatus === 'phMarket';

  return {
    botStatus: status,
    rthStatus: monitorStatus ? (isRthActive ? 'active' : 'paused') : null,
    ahStatus: monitorStatus ? (isAhActive ? 'active' : 'paused') : null,
    phStatus: monitorStatus ? (isPhActive ? 'active' : 'paused') : null,
  };
};

const SessionStatuses: FC<SessionStatusesProps> = (props) => {
  const { rthStatus, ahStatus, phStatus, botStatus } =
    useSessionStatuses(props);

  return (
    <Fragment>
      <StatusText status={botStatus} prefix="BOT Status" />
      <StatusText status={rthStatus} prefix="RTH Status" />
      <StatusText status={phStatus} prefix="Prehours Status" />
      <StatusText status={ahStatus} prefix="Afterhours Status" />
    </Fragment>
  );
};

export default SessionStatuses;
