import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { DeprecatedCreateSessionBody } from 'common/models';
import {
  withConfirmActionDialog,
  WithConfirmActionDialogSharedProps,
  Wrapper,
} from 'core/components/common';
import { CircularIndicator } from 'core/components/uikit';

import { StartStopButton, Statuses } from './components';
import useSessionDetailsStore, { useFetchSession } from './sessionDetailsStore';
import CreateEditSessionForm, {
  CreateEditSessionFormProps,
} from '../CreateEditSessionForm';

const EditSession: FC<WithConfirmActionDialogSharedProps> = ({
  showConfirmActionDialog,
}) => {
  const { t } = useTranslation();
  const { sessionId } = useParams();
  const { data, isLoading } = useFetchSession(sessionId ?? '');

  if (isLoading) {
    return (
      <Wrapper internalClassName="flex flex-col">
        <CircularIndicator />
      </Wrapper>
    );
  }

  if (data === null) {
    return (
      <Wrapper internalClassName="flex flex-col">
        <h4 className="text-center">{t('noData.session')}</h4>
      </Wrapper>
    );
  }

  const onSubmit: CreateEditSessionFormProps['onSubmit'] = async (values) =>
    showConfirmActionDialog(async () => {
      try {
        await useSessionDetailsStore
          .getState()
          .editSession(values as DeprecatedCreateSessionBody);
      } catch (error) {
        console.error(error);
      }
    });

  const { status, positions, sessionName, monitorStatus } = data;
  const isBotActive = status === 'active';
  const hasOpenPositions = (positions?.length ?? 0) > 0;
  const disabled = {
    phForm: isLoading || monitorStatus === 'phMarket',
    ahForm: isLoading || monitorStatus === 'ahMarket',
    rthForm: isLoading || monitorStatus === 'rthMarket',
    submitButton: isLoading,
    blacklist: isBotActive || isLoading,
    closeParamsForm: isBotActive || isLoading,
  };

  return (
    <Wrapper internalClassName="flex flex-col gap-4">
      <div className="flex flex-col gap-2">
        <div className="flex flex-row items-center justify-between gap-3">
          <h2>{sessionName}</h2>
          <StartStopButton
            disabled={isLoading}
            isStarted={isBotActive}
            hasOpenPositions={hasOpenPositions}
          />
        </div>
        <Statuses status={status} monitorStatus={monitorStatus} />
      </div>
      <CreateEditSessionForm
        onSubmit={onSubmit}
        disabled={disabled}
        actionButtonText={t('pages.editSession.action')}
        defaultValues={data as CreateEditSessionFormProps['defaultValues']}
      />
    </Wrapper>
  );
};

const EditSessionWithHoc = withConfirmActionDialog(EditSession);

export default EditSessionWithHoc;
